<script setup lang="ts">

import SearchInput from '@/blocks/HomeWrapper/Search/SearchInputWrapper/SearchInput.vue'
import Button from 'primevue/button'
import { useRouter } from 'vue-router';

const router = useRouter();

function goBack(){
  if (window.history.state?.back) {
    router.back(); // Возврат на предыдущую страницу
  } else {
    router.push('/'); // Перенаправление на главную страницу, если истории нет
  }
};
</script>

<template>
<div class="header-search-block-small">
  <Button
    icon="pi pi-chevron-left"
    loading-icon="pi pi-spinner"
    severity="contrast"
    @click="goBack"
    :pt="{
      root:{
        style:{
          marginTop:'4px',
          width:'48px',
          height: '48px',
          borderRadius: '50%',
        }
      }
    }"
  />
  <SearchInput />
</div>
</template>

<style scoped>
.header-search-block-small {
  display: flex !important;
  justify-content: space-between;
  gap:8px;
  background: rgba(255, 255, 255, 1);
  transition: height 1s ease;
  box-shadow: 0 4px 13px 0px rgba(0, 0, 0, 0.2);
  border-radius: 0 0 28px 28px;
  padding: 16px;
}
:deep(.search__input__wrapper) {
  position: relative;
  right: 0;
  background: transparent;
  transition: none;
  box-shadow: none;
}



</style>