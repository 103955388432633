<script setup lang="ts">
import SearchPagePagination from "@/blocks/SearchPage/SearchPageContent/SearchPageListWrapper/SearchPagePagination.vue";
import { useRoute, useRouter } from "vue-router";
import { computed, onMounted, ref, watch } from "vue";
import { useSearch } from "@/stores/search";
import { parseFiltersFromUrl } from "@/helpers/parseFiltersFromUrl";
import { useImageSearch } from "@/stores/imageSearch";
import SearchPageListItemsSkeleton from "@/blocks/SearchPage/SearchPageContent/SearchPageListWrapper/SearchPageListItems/SearchPageListItemsSkeleton.vue";
import CustomCardBlock from "@/components/UI/CustomCard/CustomCardBlock.vue";
import type { ProductCustomCardItem } from "@/types/ProductType";
import { deepEqual } from '@/helpers/deepEqual'
import { compareArrays } from '@/helpers/handlingPrice'

const search = useSearch();
const route = useRoute();
const router = useRouter();
const imageSearch = useImageSearch();


const imageUrl = computed(() => imageSearch.croppedImageLink
  ? imageSearch.croppedImageLink
  : imageSearch.currentImageLink
);

const staticFilters = computed(() => search.staticFilters)
const definedFilters = computed(() => search.responseDefineFilters);
const searchedText = computed(() => route.params.searchText);
const queryFilters = computed(() => route.query);
const currentPage = computed(() => route.query.page);

const behavior = computed(() => search.itemsBehavior);
const searchSort = computed(() => search.currentSort);
const isSearchPageItemsInfoLoading = computed(() => search.isGetSearchListLoading);

const countOfItemsInRow = computed(() => {
  if(window.innerWidth < 568) return 2;
  if(window.innerWidth < 768) return 3;
    if(window.innerWidth < 1024) return 4;
    return search.countOfItemInRow
})

const resultedSearchItems = ref<ProductCustomCardItem[]>([]);
const pageInfo = ref(null);

function productClickHandler(id: number,variantId:number) {
  router.push({ path: `/product=${id}/variant=${variantId}` });
}

async function loadMoreHandler() {
  await search.setItemsBehavior("add");
  await router.push({
    query: {
      ...route.query,
      page: route.query.page ? Number(route.query.page) + 1 : 2,
    },
  });
}

async function loadSearchPageItemsInfo() {
  const filtersFromUrlForPayload = parseFiltersFromUrl(route.query);
  const filtersToBackEnd = filtersFromUrlForPayload.length
    ? filtersFromUrlForPayload
    : definedFilters.value.length
    ? definedFilters.value
    : [];

  const response = await search.getSearchList(
    {
      dynamic_filters: filtersToBackEnd,
      static_filters:staticFilters.value,
      img_url: imageUrl.value,
      search_text: String(searchedText.value),
      sorting_option: searchSort.value,
    },
    {
      page_num: currentPage.value ? Number(currentPage.value) : 1,
      page_size: countOfItemsInRow.value * 3,
    }
  );

  if (behavior.value === "default") {
    resultedSearchItems.value = response.data.search_results;
    pageInfo.value = response.data.page_info;
  } else {
    resultedSearchItems.value = resultedSearchItems.value?.concat(
      response.data.search_results
    );
    pageInfo.value = response.data.page_info;
    await search.setItemsBehavior("default");
  }
}

watch(
  [queryFilters, countOfItemsInRow, searchSort, searchedText],
  async (value, oldValue, onCleanup) => {


    if(value && oldValue && value[0]?.hasOwnProperty('Price') && !oldValue[0]?.hasOwnProperty('Price')) {

      return
    }

    if(JSON.stringify(value) !== JSON.stringify(oldValue)) {

      await loadSearchPageItemsInfo();
    }

  },{
    deep:true,
    immediate: true,
  }
);

watch(() => [staticFilters.value.high_rating,staticFilters.value.original], async (value, oldValue, onCleanup) => {
  if(JSON.stringify(value) !== JSON.stringify(oldValue)) {
    await loadSearchPageItemsInfo();
  }
},{
  deep:true
})



</script>

<template>
  <div class="search-result-inner">
    <search-page-list-items-skeleton v-if="isSearchPageItemsInfoLoading" />
    <div v-else class="search-result-items__wrapper">
      <CustomCardBlock
        v-for="searchResultItem in resultedSearchItems"
        @click="productClickHandler(searchResultItem.id,searchResultItem.variant_id)"
        :productData="searchResultItem"
        :style="{ width: `calc(100%/${countOfItemsInRow} - 20px)` }"
      />
    </div>
    <search-page-pagination @load-more="loadMoreHandler" />
  </div>
</template>

<style scoped>
.load-more {
  margin-top: 40px;
}

.card-footer {
  display: flex;
  justify-content: space-between;
}

.pi-heart {
  cursor: pointer;
  font-size: 28px;
}

.pi-heart:hover {
  color: rgba(61, 61, 61, 1);
}

.search-result-inner {
  display: flex;
  flex-direction: column;
}

.search-result-items__wrapper {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 20px;
}

.card-item-image {
  display: flex;
  justify-content: center;
  max-width: 384px;
  max-height: 374px;
  border-radius: 32px;
  overflow: hidden;
}

.card-image {
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: center;
}
</style>
