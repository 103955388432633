<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import FloatLabel from 'primevue/floatlabel'
import { useRoute } from 'vue-router'
import { useAuthStore } from '@/stores/user'
import router from '@/router'
import Password from 'primevue/password'
import Chip from 'primevue/chip'
import { useToast } from 'primevue/usetoast'

const route = useRoute()
const toast = useToast()

const password = ref('')
const confirmPassword = ref('')

const token = ref(' ')

const sixSymbolsValidation = computed(() => password.value.length > 6)
const numberValidation = computed(() => /\d/.test(password.value))
const bigLetterValidation = computed(() => /\p{Lu}/u.test(password.value))
const smallLetterValidation = computed(() => /\p{Ll}/u.test(password.value))
const specialLetterValidation = computed(() => /[!@#$%^&*(),.?":{}|<>]/.test(password.value))

const passValidationState = computed(
  () =>
    sixSymbolsValidation.value &&
    numberValidation.value &&
    bigLetterValidation.value &&
    smallLetterValidation.value &&
    specialLetterValidation.value
)

function setNewPassword() {
  useAuthStore()
    .resetPassword({
      token: token.value,
      password: password.value
    })
    .then((res) => {
      showToast('Твой пароль успешно изменён')
    })
}

const showToast = (msg) => {
  toast.add({ severity: 'info', summary: 'Info', detail: msg, life: 3000 })
}

onMounted(() => {
  token.value = route.params.resetToken
  router.push({ path: '/' })
})
</script>

<template>
  <div class="set-new-password__content">
    <span class="set-new-password__title title-big">Задай новый пароль</span>
    <div class="set-new-password__content__wrapper">
      <div class="set-new-password__inputs">
        <FloatLabel>
          <Password
            v-model="password"
            class="password"
            type="password"
            :feedback="false"
            toggle-mask
            :pt="{
              root: {
                style: {
                  width: '100%',
                  borderRadius: '8px'
                }
              },

              input: {
                root: {
                  style: {
                    width: '100%',
                    borderRadius: '8px'
                  }
                }
              }
            }"
          />
          <label for="password">Введите пароль</label>
        </FloatLabel>
      </div>
      <div class="new-password-validations">
        <Chip
          label="минимум 6 символов"
          :icon="`pi ${sixSymbolsValidation ? 'pi-chevron-circle-down' : 'pi-circle'}`"
          :pt="{
            root: {
              style: {
                backgroundColor: sixSymbolsValidation
                  ? 'rgba(14, 105, 17, 0.05)'
                  : 'rgba(26, 33, 51, 0.05)'
              }
            },
            icon: {
              style: {
                color: sixSymbolsValidation ? 'rgba(14, 105, 17, 1)' : 'rgba(26, 33, 51, 0.05)'
              }
            }
          }"
        />
        <Chip
          label="цифра"
          :icon="`pi ${numberValidation ? 'pi-chevron-circle-down' : 'pi-circle'}`"
          :pt="{
            root: {
              style: {
                backgroundColor: numberValidation
                  ? 'rgba(14, 105, 17, 0.05)'
                  : 'rgba(26, 33, 51, 0.05)'
              }
            },
            icon: {
              style: {
                color: numberValidation ? 'rgba(14, 105, 17, 1)' : 'rgba(26, 33, 51, 0.05)'
              }
            }
          }"
        />
        <Chip
          label="Большая буква"
          :icon="`pi ${bigLetterValidation ? 'pi-chevron-circle-down' : 'pi-circle'}`"
          :pt="{
            root: {
              style: {
                backgroundColor: bigLetterValidation
                  ? 'rgba(14, 105, 17, 0.05)'
                  : 'rgba(26, 33, 51, 0.05)'
              }
            },
            icon: {
              style: {
                color: bigLetterValidation ? 'rgba(14, 105, 17, 1)' : 'rgba(26, 33, 51, 0.05)'
              }
            }
          }"
        />
        <Chip
          label="Маленькая буква"
          :icon="`pi ${smallLetterValidation ? 'pi-chevron-circle-down' : 'pi-circle'}`"
          :pt="{
            root: {
              style: {
                backgroundColor: smallLetterValidation
                  ? 'rgba(14, 105, 17, 0.05)'
                  : 'rgba(26, 33, 51, 0.05)'
              }
            },
            icon: {
              style: {
                color: smallLetterValidation ? 'rgba(14, 105, 17, 1)' : 'rgba(26, 33, 51, 0.05)'
              }
            }
          }"
        />
        <Chip
          label="Специсимвол (!$%@_?* и т.п.)"
          :icon="`pi ${specialLetterValidation ? 'pi-chevron-circle-down' : 'pi-circle'}`"
          :pt="{
            root: {
              style: {
                backgroundColor: specialLetterValidation
                  ? 'rgba(14, 105, 17, 0.05)'
                  : 'rgba(26, 33, 51, 0.05)'
              }
            },
            icon: {
              style: {
                color: specialLetterValidation ? 'rgba(14, 105, 17, 1)' : 'rgba(26, 33, 51, 0.05)'
              }
            }
          }"
        />
      </div>
    </div>
    <div class="set-new-password__submit_btn__wrapper">
      <Button
        class="set-new-password-submit"
        label="Задать новый пароль"
        :disabled="!passValidationState"
        @click="setNewPassword"
      />
    </div>
  </div>
</template>
<style scoped>
.set-new-password__content {
  display: flex;
  border-radius: 40px;
  padding: 0 40px 40px 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 480px;
}

.new-password-validations {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.set-new-password__title {
  text-align: center;
  margin-bottom: 20px;
}

.set-new-password__content__wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.set-new-password_third_party button {
  display: flex;
  border-radius: 8px;
  gap: 8px;
  padding: 12px 20px;
}

.set-new-password__inputs {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.set-new-password__inputs__wrapper {
  width: 100%;
}

.password {
  width: 100%;
}

.set-new-password__text {
  display: flex;
  justify-content: flex-end;
}

.set-new-password__submit_btn__wrapper {
  display: flex;
  width: 100%;
  margin-top: 40px;
}

.set-new-password-submit {
  width: 100%;
  border-radius: 100px;
}
</style>
