import axiosApiInstance from '@/api/api'

export const getCapsulesPreview = async (limit:number) => {
  try {
    return await axiosApiInstance.get(`/capsules/capsules`, { params: {
        limit:limit
      } })
  } catch (err) {
    throw err
  }
}