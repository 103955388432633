<script setup lang="ts"></script>

<template>
  <div class="logo">
    <router-link to="/" rel="noopener">
      <img class="fair_logo" src="../../../../assets/img/logo.svg" alt="Logo" />
    </router-link>
  </div>
</template>

<style scoped>
.logo {
  max-height: 80px;
  max-width: 108px;
  width: 100%;
}

.fair_logo {
  width: 100%;
  height: 100%;
  max-height: 80px;
  max-width: 108px;
}

.logo a {
  color: black;
  font-weight: 600;
  font-size: 42px;
}
</style>
