<template>
  <div class="custom-card-wrapper">
    <DeferredContent>
      <Card
        :pt="{
          root: {
            style: {
              position: 'relative',
              cursor: 'pointer',
              boxShadow: '0px 0px 4px -10px rgba(186, 174, 206, 0.25)',
              width: '100%',
              borderRadius: '40px',
              border: 'none',
              background: 'transparent',
            },
          },
          header: {
            style: {
              position: 'absolute',
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              padding: '8px',
              zIndex: '2',
            },
          },
          body: {
            style: {
              background: 'rgba(255, 255, 255, 0.2)',
              borderRadius: '40px',
              padding: '4px',
            },
          },
          content: {
            style: {
              width: '100%',
              height: '100%',
              clipPath: clipPath,
            },
          },

          footer: {
            style: {
              display: 'flex',
              flexDirection: 'column',
              gap: '4px',
              padding: '12px',
            },
          },
        }"
      >
        <template #header>
          <Button
            icon="pi pi-thumbs-down"
            text
            severity="primary"
            :pt="{
              root: {
                style: {
                  height: '48px',
                  width: '48px',
                  borderRadius: '50%',
                  padding: '0',
                },
              },
            }"
          />

          <Button
            icon="pi pi-heart"
            text
            severity="primary"
            :pt="{
              root: {
                style: {
                  height: '48px',
                  width: '48px',
                  borderRadius: '50%',
                  padding: '0',
                },
              },
            }"
          />
        </template>

        <template #content>
          <Galleria
            v-model:activeIndex="activeIndex"
            :value="productData.image_urls"
            :numVisible="1"
            containerStyle="max-width: 640px"
            :showThumbnails="false"
            :showIndicators="true"
            :changeItemOnIndicatorHover="true"
            :showIndicatorsOnItem="true"
            :indicatorsPosition="'bottom'"
            class="stop-touch"
            :pt="{
              root: {
                style: {
                  borderRadius: '40px',
                  border: 'none',
                  overflow: 'hidden',
                },
              },
              items: {
                style:{
                  maxHeight:'500px',
                }
              },
              indicatorList: {
                style: {
                  height: `${isDesktop ? 100 : 4}%`,
                  backgroundColor: 'transparent',
                },
              },

              indicator: {
                style: {
                  display: 'flex',
                  alignItems: 'flex-end',
                  width: `calc(100%/${productData.image_urls.length})`,
                  maxWidth: '20%',
                  height: '100%',
                },
              },
              indicatorButton: {
                style: {
                  width: `100%`,
                  height: '4px',
                  borderRadius: '100px',
                },
              },
            }"
          >
            <template #item="slotProps">
              <img
                :src="slotProps.item"
                :alt="slotProps.item"
                class="image-castom-card"
                style="width: 100%; display: block"
                @touchstart="handleTouchStart"
                @touchmove="handleTouchMove"
                @touchend="handleTouchEnd"
              />
            </template>
          </Galleria>
        </template>
        <template #footer>
          <p class="custom-card-text-description">
            <span class="castom-card-brand-name body-text">{{ productData.brand }}</span>
            <span class="castom-card-item-name body-text">{{ productData.name }}</span>
          </p>
          <div class="custom-card-footer-price-block">
            from
            <span class="price title-big"> {{ productData.price.lte }}$ </span>
          </div>
        </template>
      </Card>
    </DeferredContent>
  </div>
</template>

<script setup lang="ts">
import Card from "primevue/card";
import Button from "primevue/button";
import Galleria from "primevue/galleria";
import DeferredContent from "primevue/deferredcontent";
import { computed, ref } from 'vue'
import type { ProductCustomCardItem } from "@/types/ProductType";

const { productData } = defineProps<{
  productData: ProductCustomCardItem;
}>();

const clipPath = ref(
  "path('M547 40C547 17.9086 529.091 0 507 0H96C78.3269 0 64 14.3269 64 32V32C64 49.6731 49.6731 64 32 64V64C14.3269 64 0 78.3269 0 96V458C0 480.091 17.9086 498 40 498H507C529.091 498 547 480.091 547 458V40Z')"
);

const currentIndex = ref(0);
const touchStartX = ref(0); // Начальная позиция касания по оси X
const touchEndX = ref(0); // Конечная позиция касания по оси X

const activeIndex = ref(0);

const isDesktop = computed(() => 1024 < window.innerWidth);

const handleTouchStart = (event) => {
  touchStartX.value = event.touches[0].clientX;
};

// Обработка движения пальца
const handleTouchMove = (event) => {
  touchEndX.value = event.touches[0].clientX;
};

// Обработка окончания касания
const handleTouchEnd = () => {
  if (touchStartX.value - touchEndX.value > 50) {
    // Свайп влево
    next();
  } else if (touchEndX.value - touchStartX.value > 50) {
    // Свайп вправо
    prev();
  }
};

const next = () => {

  activeIndex.value = activeIndex.value === productData.image_urls.length - 1 ? activeIndex.value = 0 : activeIndex.value + 1;
};
const prev = () => {
  activeIndex.value = activeIndex.value === 0 ? activeIndex.value = productData.image_urls.length - 1 : activeIndex.value - 1;
};

</script>

<style scoped>
.capsulest-card-header-actions-buttons {
  display: flex;
  justify-content: space-between;
  width: 96%;
}

.castom-card-brand-name {

  color: rgba(36, 48, 70, 0.6);
}

.custom-card-text-description {
  display: flex;
  flex-direction: column;
}

.castom-card-item-name {
  color: rgba(36, 48, 70, 0.6);
}

.custom-card-footer-price-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: rgba(35, 47, 69, 0.6);
  font-weight: 600;
}

.price {
  color: rgba(35, 47, 69, 1);
}


@media screen {

}

@media screen and (max-width: 1024px) {
}



.p-card:hover {
  background: rgba(35, 47, 69, 0.1) !important;
}
</style>
