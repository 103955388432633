import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useImageSearch = defineStore('imageSearch', () => {

  const imageBase64Data = ref()
  const imageFile = ref()
  const currentImageLink = ref()
  const croppedImageLink = ref()

  const isImageSearchModalOpen = ref(false)

  function toogleImageSearchModal() {
    isImageSearchModalOpen.value = !isImageSearchModalOpen.value
  }

  return {
    imageBase64Data,
    imageFile,
    currentImageLink,
    isImageSearchModalOpen,
    toogleImageSearchModal,
    croppedImageLink
  }
})
