<template>
  <div class="testyObject">
    <Button
      @click.stop="toggle"
      severity="help"
      :pt="{
        root: {
          style: {
            backgroundColor: 'rgba(216, 229, 173, 0.8)',
            height: '40px',
            width: '40px',
            borderRadius: '50%',
            padding: '0',
          },
        },
      }"
    >
      <template #icon>
          <CustomSvgIcons

            class="scope-icon"
            :class="{
                rotate: op?.visible
             }"
            name="scope" :size="20" />
      </template>
    </Button>

    <Popover ref="op" class="testOp" appendTo="body"
      :pt="{
        root:{
          style:{
            padding: '0',
            borderRadius:'34px',
          }
        },
        content:{
          style:{
            padding:'12px',
          }
        }
      }"
    >
      <div class="ai-tip-capsules-tip">
        <slot></slot>
      </div>
    </Popover>
  </div>
</template>

<script setup lang="ts">
import Button from "primevue/button";
import Popover from "primevue/popover";
import { $dt } from '@primevue/themes';
import { ref } from "vue";
import CustomSvgIcons from '@/components/HelpersComponents/CustomSvgIcons.vue'

const op = ref();


const toggle = (event: Event) => {
  op.value.toggle(event);
};
</script>

<style scoped>
:deep(.testOp) {
  top: 50% !important;
  left: 50% !important ;
  transform: translate(0, 15%);
}


:deep(.testOp:before) {
  content:none !important;
}

:deep(.testOp:after) {
  content:none !important;
}

.testyObject {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 100000;
}

.ai-tip-capsules-text {
  width: 320px;
  color: rgba(110, 133, 203, 1);
}



.scope-icon {
  transition: transform 0.3s ease;
}

.scope-icon:hover {
  transform: scale(1.1);
}

.rotate {
  transform: rotate(45deg) !important;
}

.rotate:hover {
  transform: rotate(45deg) scale(1.1) !important;
}

</style>

<style>
.testOp:before {
  content:none !important;
}

.testOp:after {
  content:none !important;
}

</style>
