<script setup lang="ts">
import Select from 'primevue/select';
import { ref, watch } from 'vue'
import { useSearch } from '@/stores/search'

const search = useSearch()

const sortingOptions = ref([
  { name: 'По релеватности', sort: 'default' },
  { name: 'Новинки', sort: 'by_newest' },
  { name: 'По возрастанию цены', sort: 'by_price_ascending' },
  { name: 'По убыванию цены', sort: 'by_price_descending' },
  { name: 'По популярности', sort: 'by_popularity' },
  { name: 'По рейтингу', sort: 'by_rating' },
]);

const selectedSort = ref(sortingOptions.value[5]);

watch( selectedSort , () => {
  search.setSortingOptions(selectedSort.value.sort)
})


</script>

<template>
  <div class="select-wrapper">
    <Select 
            v-model="selectedSort"
            :options="sortingOptions"
            checkmark
            option-label="name"
            :highlight-on-select="false"
            :placeholder="selectedSort"
            :pt="{
              root:{
                style:{
                  backgroundColor:'white',
                  borderColor:'rgba(230, 227, 236, 1)',
                  borderRadius:'40px',
                  height:'48px',
                  width:'100%'
                }
              },
              option:{
                style:{
                  display:'flex',
                  flexDirection:'row-reverse',
                  justifyContent:'space-between',

                }
              }
            }"
    >
      <template #dropdownicon>
        <i class="pi pi-sort-alt" style="color:rgba(61, 61, 61, 1) " />
      </template>
    </Select>
  </div>
</template>

<style scoped>
.select-wrapper {
  width: 252px;
}
</style>