<script setup lang="ts">
import Button from 'primevue/button'
import { useAuthModal } from '@/stores/authModal'
import router from '@/router'
import { onMounted } from 'vue'

function loginHandler() {
  useAuthModal().showAuthModal('signIn')
}

onMounted(() => {
  router.push({ path: '/' })
})
</script>

<template>
  <div class="registration-end__content">
    <span class="registration-end__title title-big">Вы успешно зарегестрировались</span>
    <div class="registration-end__content__wrapper">
      <div class="login">
        <Button
          class="login-btn"
          label="войти"
          @click="loginHandler"
        />
      </div>
    </div>
  </div>
</template>
<style scoped>
.registration-end__content {
  display: flex;
  border-radius: 40px;
  padding: 40px;
  gap: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.registration-end__title {
  text-align: center;
}

.registration-end__content__wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.registration-end_third_party button {
  display: flex;
  border-radius: 8px;
  width: 160px;
  height: 48px;
  gap: 8px;
  padding: 12px 20px;
}

.login {
  display: flex;
  justify-content: center;
  width: 100%;
}

.login-btn {
  width: 100%;
}
</style>
