<script setup lang="ts">
import Divider from "primevue/divider";
import { computed, onMounted, onUnmounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { getRecommendationsCollections } from "@/api/recommendations";
import Button from "primevue/button";
import DeferredContent from "primevue/deferredcontent";
import RecommendationsMansoryWallSkeleton from "@/blocks/HomeWrapper/Recommended/RecommendationsMansoryWallSkeleton.vue";
import CustomCardBlock from "@/components/UI/CustomCard/CustomCardBlock.vue";
import YellowAiTip from "@/blocks/SharedBlocks/YellowAiTip.vue";

const router = useRouter();
const route = useRoute();

const isCollectionsLoading = ref(false);

const collectionsData = ref([]);
const width = ref(window.innerWidth);

const updateWidth = () => {
  width.value = window.innerWidth;
};

const gapComputed = computed(() => {
  return width.value < 1024 ? 16: 40
})

const numberOfColumnsForMansoryWall = computed(() => {
  if(width.value < 660) {
    return 2
  }
  return width.value < 1440 ? 3 : 4;
});

async function loadCollectionsData() {
  isCollectionsLoading.value = true;

  const res = await getRecommendationsCollections(24, route.query.audience);
  collectionsData.value = res.data.items;

  isCollectionsLoading.value = false;
}

watch(
  route,
  async () => {
    if (route.query.audience) {
      await loadCollectionsData();
    }
  },
  { deep: true }
);

onMounted(async () => {
  window.addEventListener("resize", updateWidth);
});

onUnmounted(() => {
  window.removeEventListener("resize", updateWidth);
});
</script>
<template>
  <DeferredContent @load="loadCollectionsData">
    <div class="mansory-wall-backgrund">
      <div class="recommendation_content_wrapper">
        <div class="recommendation_top_content__wrapper">
          <div class="top-content__text">
            <div class="popular-items__header-title">
              <span class="popular-items_title title-large">Popular today</span>
              <yellow-ai-tip
                ai-tip-text="Products that many people liked for the past week"
              />
            </div>
          </div>
        </div>
        <recommendations-mansory-wall-skeleton v-if="isCollectionsLoading" />
        <div v-else class="mansory-wall-wrapper">
          <masonry-wall
            :items="collectionsData"
            :min-columns="numberOfColumnsForMansoryWall"
            :max-columns="4"
            :gap="gapComputed"
          >
            <template #default="{ item, index }">
              <div class="mansory-item__wrapper">
                <CustomCardBlock :productData="item" />
              </div>
            </template>
          </masonry-wall>
        </div>
        <div class="mobile">
          <Button
            label="Показать больше популярных товаров"
            severity="secondary"
            icon="pi pi-arrow-right"
            icon-pos="right"
            class="btn-styc"
          />
        </div>
        <div class="show-more-popular-btn desktop">
          <Button
            label="Показать больше популярных товаров"
            severity="help"
            icon="pi pi-arrow-right"
            icon-pos="right"
            class="btn-styc"
          />
        </div>
      </div>
    </div>
  </DeferredContent>
</template>

<style scoped>
.mansory-wall-backgrund {
  width: 100%;
}

.pi-heart {
  cursor: pointer;
}

.show-more-popular-btn {
  position: sticky;
  margin-top: 150px;
  bottom: 50px;
  left: 36%;
  width: fit-content;
}

.brand-name-mansory-item {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.text-subitem {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.mansory-item-text__wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 32px;
  gap: 12px;
  color: rgba(61, 61, 61, 1);
}

.image-mansory {
  width: 100%;
  height: 100%;
  border-radius: 32px;
}

.top-content-filters {
  display: flex;
  flex-direction: column;
  padding: 12px 0;
  gap: 16px;
}

.top-content-upper-filters__wrapper {
  display: flex;
  gap: 8px;
}

.top-content-selected-filters__wrapper {
  display: flex;
  gap: 8px;
}

.top-content-filters__item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  border: 1px solid rgba(115, 99, 84, 1);
  border-radius: 40px;
  padding: 16px 20px 16px 12px;
  color: rgba(115, 99, 84, 1);
}

.top-content-selected-filters__item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  border: 1px solid rgba(147, 120, 101, 0.1);
  border-radius: 40px;
  padding: 4px 12px;
  color: rgba(115, 99, 84, 1);
  background: rgba(147, 120, 101, 0.1);
  gap: 8px;
}

.top-content__text {
  display: flex;
}

.recommendation_content_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
  width: 100%;
  background: linear-gradient(#f1eff5, #e9edf7);
  gap: 40px;
}

.mansory-wall-wrapper {
  max-height: 4200px;
  width: 90%;
  position: relative;
}

.mansory-item-img__wrapper {
  width: 100%;
  border-radius: 32px;
}

.recommendation_top_content__wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 90%;
}

.popular-items__header-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.popular-items_title {
  color: rgba(35, 47, 69, 1);
}

:deep(.masonry-wall) {
  max-height: 4500px;
  overflow: hidden;
}

.mobile {
  display:none
}


@media screen and (max-width: 1024px) {
  .desktop {
    display: none;
  }

  .mobile {
    display: initial;
  }

  .mansory-wall-wrapper {
    max-height: none;
  }

  .recommendation_content_wrapper {
    padding-bottom: 20px;
  }
}


</style>
