<script setup lang="ts">
import Divider from 'primevue/divider'
import InputText from 'primevue/inputtext'
import Button from 'primevue/button'
import FileUpload from 'primevue/fileupload'
import { ref } from 'vue'

const emits = defineEmits(['customUploadImage', 'closeModal', 'setBase64ImageData'])

const fileUpload = ref();

const imageUrl = ref(null)
const isDragged = ref(false)

function handleDragEnter() {
  isDragged.value = true
}

function handleDragOver(event) {
  event.preventDefault()
}

function handleDragLeave() {
  isDragged.value = false
}

function upload() {
  fileUpload.value.choose()
}

function uploadImage(event) {
  isDragged.value = false
  emits('customUploadImage', event)
}

function closeModalHandler() {
  emits('closeModal')
}

async function convertToBase64() {
  if (!imageUrl.value) {
    alert('Пожалуйста, введите URL изображения.')
    return
  }

  try {
    const response = await fetch(imageUrl.value)
    const blob = await response.blob()

    const reader = new FileReader()
    reader.onloadend = () => {
      emits('setBase64ImageData', reader.result)
    }
    reader.readAsDataURL(blob)
  } catch (error) {
    console.error('Ошибка при конвертации изображения:', error)
    alert('Не удалось загрузить и конвертировать изображение. Проверьте URL и попробуйте снова.')
  }
}
</script>

<template>
  <div class="file-upload__wrapper">
    <FileUpload
      ref="fileUpload"
      :pt="{
        root: {
          style: {
            border: 'none'
          }
        },
        header: {
          style: {
            display: 'none'
          }
        },
        content: {
          style: {
            padding: '0 40px 40px 40px',
            background: 'white'
          }
        }
      }"
      name="demo[]"
      url="/api/upload"
      accept="image/*"
      custom-upload
      :auto="true"
      @uploader="uploadImage"
    >
      <template #content="{ files, uploadedFiles, removeUploadedFileCallback, removeFileCallback }">
        <div v-if="files.length > 0">
          <h5>Pending</h5>
        </div>
      </template>
      <template #empty>
        <div
          class="file-upload-empty-content__wrapper"
          @dragenter.prevent="handleDragEnter"
          @dragover.prevent="handleDragOver"
          @dragleave.prevent="handleDragLeave"
        >
          <div
            :class="{
              'drop-image__wrapper': true,
              'drop-active': isDragged
            }"
          >
            <div class="default-drag-and-drop-icon">
              <svg
                width="80"
                height="80"
                viewBox="0 0 80 80"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_59310_5027)">
                  <path
                    d="M12 0H68C71.1797 0.00949636 74.2264 1.27683 76.4748 3.52521C78.7232 5.77359 79.9905 8.82032 80 12V55.4295L80.0008 67.9983C80.0048 71.1023 78.808 74.1017 76.64 76.3217C75.5212 77.4845 74.1795 78.4097 72.6949 79.042C71.2104 79.6743 69.6136 80.0007 68 80.0017H12C8.82032 79.9922 5.77359 78.7249 3.52521 76.4765C1.27683 74.2281 0.00949636 71.1814 0 68.0017V47.44V12C0.00949636 8.82032 1.27683 5.77359 3.52521 3.52521C5.77359 1.27683 8.82032 0.00949636 12 0Z"
                    fill="#E1D5BF"
                  />
                  <path
                    d="M50.1601 40.3905C52.4469 38.2139 55.4831 37 58.6401 37C61.7972 37 64.8333 38.2139 67.1201 40.3905L82.6401 55.9105V76.4705C82.6306 79.6502 81.3633 82.6969 79.1149 84.9453C76.8665 87.1937 73.8198 88.461 70.6401 88.4705H14.6401C13.0266 88.4695 11.4297 88.1431 9.9452 87.5108C8.46066 86.8785 7.11889 85.9533 6.00014 84.7905L50.1601 40.3905Z"
                    fill="#A2B8E9"
                  />
                  <path
                    d="M32.48 31.9217C30.1932 29.7452 27.157 28.5312 24 28.5312C20.843 28.5312 17.8068 29.7452 15.52 31.9217L0 47.4417V68.0017C0.00949636 71.1814 1.27683 74.2281 3.52521 76.4765C5.77359 78.7249 8.82032 79.9922 12 80.0017H68C69.6136 80.0007 71.2104 79.6743 72.6949 79.042C74.1795 78.4097 75.5212 77.4845 76.64 76.3217L32.48 31.9217Z"
                    fill="#24314A"
                  />
                  <circle
                    cx="46.0301"
                    cy="18.5326"
                    r="7.71859"
                    fill="#937865"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_59310_5027">
                    <rect
                      width="80"
                      height="80"
                      rx="12"
                      fill="white"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <p>
              Перетащи изображение сюда или
              <span @click="upload" class="photo-upload-btn body-text-small">Загрузи фото</span>
            </p>
          </div>
          <div class="divider-inner">
            <Divider
              align="center"
              type="solid"
              :pt="{
                content: {
                  style: {
                    background: 'rgba(245, 245, 247, 1)',
                    padding: '0 24px'
                  }
                }
              }"
            >
              <b>или</b>
            </Divider>
          </div>

          <div class="image-link-wrapper">
            <InputText
              v-model="imageUrl"
              type="text"
              placeholder="Вставь ссылку на изображение"
            />
            <div class="find-btn__wrapper">
              <Button
                label="Загрузить"
                severity="secondary"
                @click="convertToBase64"
              />
            </div>
          </div>
        </div>
      </template>
    </FileUpload>
  </div>
</template>

<style scoped>
:deep(.p-fileupload-buttonbar) {
  display: none;
}

.file-upload__wrapper {
  max-width: 640px;
  border-radius: 20px;
  background: rgba(245, 245, 247, 1);
  overflow: hidden;
}

.file-upload-empty-content__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.drop-image__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 22px;
  border: 1px dashed rgba(145, 145, 145, 1);
  border-radius: 20px;
  width: 560px;
  height: 254px;
}

.photo-upload-btn {
  color: rgba(70, 107, 189, 1);
  cursor: pointer;
}

.close {
  cursor: pointer;
  top: 14px;
  right: 20px;
  position: absolute;
}

.image-link-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  width: 100%;
}

.drop-active {
  border: 1px dashed rgba(165, 201, 255, 1);
  background: rgba(162, 184, 233, 0.2);
}

.image-link-wrapper .p-inputtext {
  width: 100%;
  height: 40px;
  border-radius: 8px;
}

.divider-inner {
  width: 100%;
}
</style>
