<script setup lang="ts">
import {
  Autoplay,
  Controller,
  FreeMode,
  Navigation,
  Virtual,
  Pagination,
} from "swiper/modules";
import { onMounted, ref } from "vue";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/free-mode";
import { Swiper, SwiperSlide} from "swiper/vue";
import Control from "@/components/UI/controllSwiper/control.vue";

import AiTipForCapsules from "./AiTipForCapsules.vue";
import CapsulesAiTip from "./CapsulesAiTip.vue";
import BrandsSkeleton from "@/blocks/HomeWrapper/PopularBrands/BrandsSkeleton.vue";
import DeferredContent from "primevue/deferredcontent";
import YellowAiTip from "@/blocks/SharedBlocks/YellowAiTip.vue";


import { getCapsulesPreview } from '@/api/capsules'
import type { CapsulesPreview } from '@/types/CapsulesTypes'


//old
const swiperModules = [FreeMode, Autoplay, Navigation, Controller, Virtual, Pagination];
const capsules = ref<CapsulesPreview[]>();

const isCapsulesLoading = ref(false);

async function loadBrandsData() {
  isCapsulesLoading.value = true;

  const res = await getCapsulesPreview(10);
  capsules.value = res.data;

  isCapsulesLoading.value = false;
}



</script>

<template>
  <DeferredContent @load="loadBrandsData">
    <div class="capsules__wrapper">
      <brands-skeleton v-if="isCapsulesLoading" />
      <div v-else class="swp-wrp">
        <swiper
          :free-mode="true"
          :space-between="20"
          :slidesPerGroup="3.5"
          :breakpoints="{
            360: {
              slidesPerView: 1.25,
            },
            480: {
              slidesPerView: 1.5,
            },
            768: {
              slidesPerView: 2.5,
            },
            1024:{
              slidesPerView: 3.2,
            },

            1920: {
              slidesPerView: 3.2,
            },
          }"
          :modules="swiperModules"
          :pagination="{
            clickable: true,
          }"
          class="capsules-carousel__container"
        >
          <template #container-start>
            <div class="capsules__header">
              <div class="capsules__header-title">
                <span class="capsules__title title-large">Capsules</span>
                <yellow-ai-tip ai-tip-text="products that are easy to combine" />
              </div>
              <control :control-color="'rgba(35, 47, 69, 1)'" class="hide" />
            </div>
          </template>
          <swiper-slide
            v-for="(capsuleItemForAgregation, index) in capsules"
            :key="index"
            class="company-carousel__item"
          >
            <div class="capsules-card-wrapper">
            <swiper class="swiper-for-carousel-items">
              <swiper-slide v-for="capsuleItem in capsuleItemForAgregation.items">
                  <div class="capsulest-card-main-content">
                    <div
                      v-for="(productOfCapsuleItem, index) in capsuleItem.capsule_items"
                      :key="productOfCapsuleItem.product_id"
                      :class="`capsules-image ${productOfCapsuleItem.product_type}`"
                      :style="{
                        backgroundImage: `url(${productOfCapsuleItem.image_url})`,
                        backgroundSize:'contain',
                        backgroundPosition: 'center center',
                      }"
                    >
                      <ai-tip-for-capsules>
                        <CapsulesAiTip
                          :price="productOfCapsuleItem.price"
                          :image="`${productOfCapsuleItem.image_url}`"
                          :brand="productOfCapsuleItem.brand_name"
                          :title="productOfCapsuleItem.product_name"
                          :id-of-product="productOfCapsuleItem.product_id"
                          :id-of-variant="productOfCapsuleItem.product_variant_id"
                        />
                      </ai-tip-for-capsules>
                    </div>
                  </div>
              </swiper-slide>
            </swiper>

            <div class="capsulest-card-footer">
              <span class="capsulest-card-footer-title title-big">{{capsuleItemForAgregation.name}}</span>
              <span class="capsulest-card-footer-description body-text ">{{capsuleItemForAgregation.description}}</span>
            </div>
      </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </DeferredContent>
</template>

<style scoped>


.capsules-carousel__container {
  padding-bottom: 30px;
}
:deep(.capsules__header) {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 220px 20px 0;
}

.capsules__header-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.capsules__title {
  color: rgba(35, 47, 69, 1);
}

.capsules__wrapper {
  display: flex;
  padding-left: 100px;
  flex-direction: column;
  min-height: 670px;
  width: 100%;
  justify-content: center;
  border-top-right-radius: 40px;
  border-top-left-radius: 40px;
}

.capsules-card-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  border-top-right-radius: 40px;
  border-top-left-radius: 40px;
  overflow: hidden;
}

.capsulest-card-header-actions-buttons {
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 96%;
  top: 6px;
  z-index: 10;
}

.capsulest-card-main-content {
  height: 500px;
  width: 100%;
  position: relative;
  background: linear-gradient(rgba(110, 133, 203, 0.4), rgba(230, 227, 236, 0));
}

.logo-brand {
  width: 100%;
}

.adv-images {
  width: 100%;
  height: 100%;
  transition: transform 0.4s ease-in-out;
}

.logo-img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  top: 35%;
  position: absolute;
}

.capsules-image {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-position: center;
  background-origin: border-box;
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: cover;

  filter: drop-shadow(0 5px 6px rgba(0, 0, 0, 0.2));
}

.outerwear {
  top:0;
  left:0;
  height: 100%;
  width: 48%;
  background-repeat: no-repeat;
  z-index: 2;
}

.top {
  top: 0;
  left: 26%;
  height: 60%;
  width: 48%;
  background-repeat: no-repeat;
  z-index: 6;
}

.bottom {
  top: 0;
  right: 0;
  height: 100%;
  width: 48%;
  background-repeat: no-repeat;
  z-index: 3;
}

.dress {
  top: 0;
  right: 0;
  height: 100%;
  width: 48%;
  background-repeat: no-repeat;
  z-index: 3;
}

.accessory_1 {
  top: 0;
  left: 22%;
  height: 28%;
  width: 25%;
  z-index: 7;
}

.accessory_2 {
  top: 0;
  right: 22%;
  height: 28%;
  width: 25%;
  z-index: 8;
}

.footwear {
  bottom: 0;
  right: 15%;
  height: 40%;
  width: 36%;
  z-index: 4;
}

.bag {
  bottom: 0;
  left: 15%;
  height: 40%;
  width: 36%;
  z-index: 5;
}

.img-on-capsule {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.capsulest-card-footer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.capsulest-card-footer-title {
  color: rgba(35, 47, 69, 1);
}

.capsulest-card-footer-description {
  color: rgba(35, 47, 69, 0.6);
}

.swiper-for-carousel-items {
  width: 100%;
}

:deep(.swiper-pagination-bullet) {
  width: 24px;
  height: 4px;
  border-radius: 100px;
}

@media (max-width: 1720px) {
  .capsulest-card-main-content {
    height: 354px;
  }
}

@media (max-width: 1366px) {
  .capsulest-card-main-content {
    height: 280px;
  }
}

@media (max-width: 1024px) {
  .capsulest-card-main-content {
    height: 280px;
  }

  .capsules__wrapper {
    padding: 0;
  }

  .hide {
    display: none;
  }

  .capsules-card-wrapper {
    height:460px;
  }

  :deep(.capsules__header) {
    padding: 16px;
  }
}
</style>

<style>
.swiper-wrapper {
  -webkit-transition-timing-function: linear !important;
  -o-transition-timing-function: linear !important;
  transition-timing-function: linear !important;
}
</style>
