<script setup lang="ts">
import { computed } from 'vue';

interface Props {
  prefix?: string;
  name: string;
  color?: string;
  size?:number
}

const props = withDefaults(defineProps<Props>(), {
  prefix: 'icon',
  color: '#fff',
  size:20
});

const symbolId = computed(() => `#${props.prefix}-${props.name}`);
</script>

<template>
  <svg aria-hidden="true" color="#fff" :width="size" :height="size" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <use :href="symbolId"  />
  </svg>
</template>

<style scoped>
/* Добавь стили, если нужно */
</style>