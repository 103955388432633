<script setup lang="ts">
import {
  Autoplay,
  Controller,
  FreeMode,
  Navigation,
  Virtual,
  Pagination,
} from "swiper/modules";
import { onMounted, ref } from "vue";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { Swiper, SwiperSlide, useSwiper } from "swiper/vue";
import Control from "@/components/UI/controllSwiper/control.vue";
import { getPopularBrands } from "@/api/brands";
import BrandsSkeleton from "@/blocks/HomeWrapper/PopularBrands/BrandsSkeleton.vue";
import DeferredContent from "primevue/deferredcontent";
import YellowAiTip from "@/blocks/SharedBlocks/YellowAiTip.vue";

const swiperModules = [FreeMode, Autoplay, Navigation, Controller, Virtual, Pagination];
const brands = ref();

const isBrandsLoading = ref(false);

async function loadBrandsData() {
  isBrandsLoading.value = true;

  const res = await getPopularBrands({
    page_num: 1,
    page_size: 16,
  });
  brands.value = res.data.brands;

  isBrandsLoading.value = false;
}
</script>

<template>
  <DeferredContent @load="loadBrandsData">
    <div class="popular-brands__wrapper">
      <brands-skeleton v-if="isBrandsLoading" />
      <div v-else class="swp-wrp">
        <swiper
          :free-mode="true"
          :space-between="20"
          :slides-offset-after="20"
          :slidesPerGroup="9"
          :pagination="{
            clickable: true,
          }"
          :breakpoints="{
            360: {
              slidesPerView: 4,
            },
            768:{
              slidesPerView: 5.5,
            },
            1024: {
              slidesPerView: 7.5,
            },
            1920: {
              slidesPerView: 9,
            },
          }"
          :modules="swiperModules"
          class="company-carousel__container"
        >
          <template #container-start>
            <div class="popular-brands__header">
              <div class="popular-brands__header-title">
                <span class="popular-brands__title title-large">Your favorite brands</span>
                <yellow-ai-tip
                  ai-tip-text="based on your most frequently viewed products"
                />
              </div>
              <control :control-color="'rgba(35, 47, 69, 1)'" />
            </div>
          </template>
          <swiper-slide
            v-for="(company, index) in brands"
            :key="index"
            class="company-carousel__item"
          >
            <div v-if="company?.background" class="adv-images__wrapper">
              <img class="adv-images" :src="company.background" />
              <div class="logo-img">
                <img class="logo-brand" :src="company.logo_url" />
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </DeferredContent>
</template>

<style scoped>
:deep(.popular-brands__header) {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 220px 60px 0;
}

.popular-brands__header-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.popular-brands__title {
  color: rgba(35, 47, 69, 1);
}

.popular-brands__wrapper {
  display: flex;
  padding-left: 100px;
  flex-direction: column;
  min-height: 670px;
  width: 100%;
  justify-content: center;
  background: rgba(255, 255, 255, 1);
  border-radius: 40px;
}

.adv-images__wrapper:hover .adv-images {
  transform: scale(1.2);
  transition: transform 0.4s ease-in-out;
}

.adv-images__wrapper {
  border-radius: 40px;
  cursor: pointer;
  width: 100%;
  height: 100%;
  object-fit: fill;
  position: relative;
}

.logo-brand {
  width: 100%;
}

.adv-images {
  width: 100%;
  height: 100%;
  transition: transform 0.4s ease-in-out;
}

.logo-img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  top: 35%;
  position: absolute;
}

.company-carousel__item {
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  border-radius: 40px;
  text-align: center;
  width: auto;
  overflow: hidden;
}

:deep(.swiper-pagination-bullet) {
  width: 24px;
  height: 4px;
  border-radius: 100px;
}

:deep(.swiper-wrapper) {
  padding-bottom: 40px;
}

@media (max-width: 1024px) {
  .popular-brands__wrapper {
    padding: 20px;
    min-height:468px;
    background: transparent;
  }

  .adv-images {
    max-height: 124px;
  }

  .popular-brands__header {
    padding: 0;
  }

  .company-carousel__item {
    max-height: 124px;
    border-radius: 28px;
  }

  :deep(.popular-brands-control) {
    display: none;
  }

  :deep(.swiper-wrapper) {
    padding-top: 20px;
  }
}
</style>

<style>
.swiper-wrapper {
  -webkit-transition-timing-function: linear !important;
  -o-transition-timing-function: linear !important;
  transition-timing-function: linear !important;
}
</style>
