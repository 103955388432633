<script setup lang="ts">
import Avatar from "primevue/avatar";
import { computed, ref } from "vue";
import { useAuthStore } from "@/stores/user";

const emit = defineEmits<{
  (e: "logout"): void;
}>();

const auth = useAuthStore();

const profileContent = ref([
  {
    title: "Уведомления",
    icon: "pi-bell",
  },
  {
    title: "Личные данные",
    icon: "pi-user",
  },
  {
    title: "Списки",
    icon: "pi-th-large",
  },
  {
    title: "Сравнение",
    icon: "pi-list",
  },
  {
    title: "Избранное",
    icon: "pi-heart",
  },
  {
    title: "Настройка предпочтений",
    icon: "pi-cog",
  },
  {
    title: "Избранное",
    icon: "pi-heart",
    class: "active-for-mobile",
  },
  {
    title: "Интернационализация",
    icon: "pi-globe",
    class: "active-for-mobile",
  },
]);

const userName = computed(() => auth.userInfo.name);
const logoutContent = ref("Выйти");

const logoutHandler = (event: Event) => {
  emit("logout");
};
</script>

<template>
  <div class="profile-content__wrapper">
    <div class="username-icon__wrapper">
      <span v-if="userName" class="username">{{ userName }}</span>
      <Avatar label="P" shape="circle" />
    </div>
    <div
      v-for="item in profileContent"
      class="profile-content-item__wrapper"
      :class="item?.class"
    >
      <i :class="`pi ${item.icon}`" style="font-size: 1rem" />
      <span>{{ item.title }}</span>
    </div>
    <div class="profile-content-item__wrapper logout" @click.stop="logoutHandler">
      <i
        class="pi pi-sign-out"
        :style="{
          fontSize: '1rem',
        }"
      />
      {{ logoutContent }}
    </div>
  </div>
</template>

<style scoped>
.profile-content__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}

.profile-content-item__wrapper {
  display: flex;
  width: 100%;
  padding: 8px 16px 8px 16px;
  gap: 12px;
  justify-content: flex-start;
  align-items: center;
}

.username-icon__wrapper {
  display: flex;
  width: 100%;
  padding: 8px 16px 8px 16px;
  gap: 12px;
  justify-content: space-between;
  align-items: center;
}

.logout {
  cursor: pointer;
  color: rgba(186, 26, 26, 1);
}

.active-for-mobile {
  display: none;
}

@media screen and (max-width: 1024px) {
  .active-for-mobile {
    display: flex;
  }
}
</style>
