<template>
  <div ref="wraperCanvas" class="select-img__wrapper">
    <div class="items-on-image-chips-wrapper">
      <chip v-for="(cropItem,index) in arrayOfImagesParts"
            @click="setCoordinates(cropItem)"
            :label="index+cropItem.width"
      />
    </div>
    <Cropper
      ref="cropper"
      :src="base64String"
      :resizeImage="{ wheel: false }"
      :stencil-props="{
        handlers: {
          eastNorth: true,

          north: false,

          westNorth: true,

          west: false,

          westSouth: true,

          south: false,

          eastSouth: true,

          east: false,
        },
      }"
      :maxWidth="imageWidth"
      :maxHeight="imageHeight"
      :imageRestriction="'stencil'"
      :debounce="1000"
      :defaultVisibleArea="{
        width: imageWidth,
        height: imageHeight,
        left: 0,
        top: 0,
      }"
      :defaultSize="{
        width: imageWidth,
        height: imageHeight,
      }"
      @ready="sendImage"
      @change="saveLastSelectedArea"
    />

    <Button
      v-for="(item, index) in arrayOfImagesParts"
      @click="setCoordinates(item)"
      icon="pi pi-plus"
      severity="secondary"
      class="tessadasdt"
      :pt="{
        root: {
          style: {
            backgroundColor: 'rgba(216, 229, 173, 0.8)',
            height: '40px',
            width: '40px',
            borderRadius: '50%',
            padding: '0',
          },
        },
      }"
      :style="{
        top: `${item.y + item.height}px`,

        left: `${item.x + item.width}px`,
      }"
    >
    </Button>
    <div class="cropper-load-buttons">
      <Button label="Back" style="width:100%" severity="secondary" outlined  @click="emit('getBackToPreviousStep')" />
      <Button label="Load" :loading="isPhotoLoading" style="width:100%" @click="sendSelectedArea(lastSelectedImage)" />
    </div>
  </div>
</template>

<script setup lang="ts">
import Button from "primevue/button";

import { ref, watch } from "vue";

import { getImageParts } from "@/api/product";

import type { ImagePartItem } from "@/types/ProductType";

import { uploadImage } from "@/api/search";

import Chip from 'primevue/chip';

import { Cropper } from "vue-advanced-cropper";

import "vue-advanced-cropper/dist/style.css";
import { useToast } from 'primevue/usetoast'

const { currentImageLink,imageWidth,imageHeight } = defineProps<{
  currentImageLink: string;
  imageWidth:number;
  imageHeight:number
}>();

const emit = defineEmits<{
  (e: "setNewImageLink", newImageLink: string): void;
  (e: "getBackToPreviousStep"):void
}>();


const toast = useToast()

const arrayOfImagesParts = ref<ImagePartItem[]>();
const base64String = ref<string>();
const cropper = ref();

const workingImageCode = ref();
const prevResult = ref();

const lastSelectedImage = ref()

const isPhotoLoading  = ref(false)
// еще немножко пиздеца


const img = new Image();

img.crossOrigin = "Anonymous";
img.src = currentImageLink;
img.onload = function () {
  const targetWidth = imageWidth; // Целевая ширина
  const targetHeight = imageHeight; // Целевая высота
  // Создаем canvas с фиксированными размерами
  const canvas = document.createElement("canvas");
  canvas.width = targetWidth;
  canvas.height = targetHeight;

  const ctx = canvas.getContext("2d");

  // Вычисляем коэффициенты масштабирования для ширины и высоты
  const scaleX = targetWidth / img.width;
  const scaleY = targetHeight / img.height;

  // Выбираем минимальный коэффициент масштабирования, чтобы вписать изображение
  const scale = Math.min(scaleX, scaleY);

  // Вычисляем новые размеры изображения
  const drawWidth = img.width * scale;
  const drawHeight = img.height * scale;

  // Центрируем изображение на canvas
  const offsetX = (targetWidth - drawWidth) / 2; // Вычисляем отступ по горизонтали
  const offsetY = (targetHeight - drawHeight) / 2; // Вычисляем отступ по вертикали

  // Заливаем фон (например, белый)
  ctx.fillStyle = "white"; // Можно заменить на другой цвет
  ctx.fillRect(0, 0, targetWidth, targetHeight);

  // Рисуем изображение на canvas
  ctx.drawImage(img, offsetX, offsetY, drawWidth, drawHeight);

  // Получаем Base64 строку
  base64String.value = canvas.toDataURL("image/jpeg"); // Или "image/png"
};

function setCoordinates(item: ImagePartItem) {
  cropper.value.setCoordinates(({ coordinates, imageSize }) => ({
    left: item.x,
    top: item.y,
    width: item.width,
    height: item.height,
  }));
}

async function sendSelectedArea(file) {
  if (file) {
    isPhotoLoading.value = true
    const res = await uploadImage(file);

    if (res.status) {
      workingImageCode.value = res.data.img_url;
      toast.add({ severity: 'info', summary: 'Info', detail: 'Photo is selected', life: 3000 })
    }
    isPhotoLoading.value = false
    return res;
  }
}

async function saveLastSelectedArea() {
  if (!cropper.value) return;

  const result = cropper.value.getResult();

  if (JSON.stringify(result) === JSON.stringify(prevResult.value)) return;
  prevResult.value = result;

  if (result && result.canvas) {
    const blob = await new Promise((resolve) => result.canvas.toBlob(resolve));

    lastSelectedImage.value = new File([blob], "imageSoure.png", { type: "image/png" });
  }
}

async function sendImage() {
  await saveLastSelectedArea()

  if(lastSelectedImage.value) {
    const res = await sendSelectedArea(lastSelectedImage.value);
  }

  if (workingImageCode.value) {
    const res = await getImageParts(workingImageCode.value);

    if (res.status === 200) {
      arrayOfImagesParts.value = res.data.product_image_parts_data;
      setCoordinates(res.data.product_image_parts_data[0])
    }
  }
}

//

watch(
  workingImageCode,
  async () => {
    emit("setNewImageLink", workingImageCode.value);
  },

  {
    deep: true,
  }
);
</script>

<style>

.cropper-load-buttons {
  display: flex;
  justify-content: center;
  gap: 8px;
  padding:20px;
}

.items-on-image-chips-wrapper {
  height: 44px;
}

.select-img__wrapper {
  position: relative;

  height: 100%;
}

.tessadasdt {
  position: absolute;

  width: 36px;

  height: 36px;

  border-radius: 50%;
}

.start-free-draw-mode {
  bottom: 30px;

  left: 30px;
}

.refresh-image {
  bottom: 30px;

  right: 30px;
}

.canv-cls {
  height: 100%;
}

.vue-simple-handler--east-north {
  display: block;

  background: transparent;

  border: 4px solid rgba(110, 133, 203, 1);

  width: 24px;

  height: 24px;

  border-top-right-radius: 8px;

  border-left: none;

  border-bottom: none;

  transform: translate(-35%, 44%);
}

.vue-simple-handler--east-south {
  display: block;

  background: transparent;

  border: 4px solid rgba(110, 133, 203, 1);

  width: 24px;

  height: 24px;

  border-bottom-right-radius: 8px;

  border-left: none;

  border-top: none;

  transform: translate(-35%, -44%);
}

.vue-simple-handler--west-north {
  display: block;

  background: transparent;

  border: 4px solid rgba(110, 133, 203, 1);

  width: 24px;

  height: 24px;

  border-top-left-radius: 8px;

  border-right: none;

  border-bottom: none;

  transform: translate(35%, 44%);
}

.vue-simple-handler--west-south {
  display: block;

  background: transparent;

  border: 4px solid rgba(110, 133, 203, 1);

  width: 24px;

  height: 24px;

  border-bottom-left-radius: 8px;

  border-right: none;

  border-top: none;

  transform: translate(35%, -44%);
}

.vue-simple-line {
  border-color: transparent !important;
}
</style>
