<script setup lang="ts">
import { RouterView } from 'vue-router'
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import { useAuthStore } from '@/stores/user'
import Toast from 'primevue/toast'
import { useToast } from 'primevue/usetoast'
import { webSocketService } from '@/service/websocketService'
import AuthWrapper from '@/blocks/Modals/AuthModals/AuthWrapper.vue'

const toast = useToast()
const auth = useAuthStore()

const token = computed(() => auth.userInfo.accessToken)

const showToast = (msg:string) => {
  toast.add({ severity: 'info', summary: 'Info', detail: msg, life: 3000 })
}

const handleWebSocketMessage = (event: MessageEvent<any>) => {
  const message = event.data; // extract the message data from the MessageEvent object
  showToast(message);
};

watch(token,async () => {
  if (token.value) {
    await auth.getUserInfo()
    if (auth.userInfo.accessToken) {
      webSocketService.connect(auth.userInfo.accessToken)
    }
    webSocketService.on(handleWebSocketMessage)
  }
})

onMounted(async () => {
  if (token.value) {
    await auth.getUserInfo()
    if (auth.userInfo.accessToken) {
      webSocketService.connect(auth.userInfo.accessToken)
    }
    webSocketService.on(handleWebSocketMessage)
  }
})

onUnmounted(() => {
  webSocketService.disconnect()
})
</script>

<template>
  <div class="wrapper">
    <Toast />
    <RouterView />
    <auth-wrapper />
  </div>
</template>

<style>
.wrapper {
  display: flex;
  justify-content: center;
}

.title-large {
  font-size: 48px;
  font-weight: 500;
  line-height: 58px;
}

.title-big {
  font-size: 36px;
  font-weight: 500;
  line-height: 44px;
}

.title-small {
  font-size: 24px;
  font-weight: 500;
  line-height: 27px;
}

.body-text {
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
}


.body-text-small {
  font-size: 16px;
  line-height: 21px;
}

.caption-text {
  font-size: 14px;
  line-height: 21px;
}


@media screen and (max-width: 1024px) {
  .title-large {
    font-size: 32px;
    font-weight: 500;
    line-height: 34px;
  }

  .title-big {
    font-size: 24px;
    font-weight: 500;
    line-height: 26px;
  }

  .title-small {
    font-size: 20px;
    font-weight: 500;
    line-height: 22px;
  }

  .body-text {
    font-size: 15px;
    line-height: 20px;
  }


  .body-text-small {
    font-size: 13px;
    line-height: 16px;
  }

  .caption-text {
    font-size: 13px;
    line-height: 16px;
    font-weight: 600;
  }
}


*::-webkit-scrollbar {
  width: 4px; /* Ширина всего элемента навигации */
}

*::-webkit-scrollbar-track {
  background: transparent; /* Цвет дорожки */
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(145, 145, 145, 1); /* Цвет бегунка */
  border-radius: 100px; /* Округление бегунка */
  border: 1px solid rgba(145, 145, 145, 1); /* Оформление границ бегунка */
}

:deep(.p-select-list-container) {
  background: grey !important;
}

.pi-heart:hover {
  animation: tilt-n-move-shaking 0.25s infinite;
}

.pi-spinner {
  animation: rotateSpinner 800ms linear infinite;
}

@keyframes tilt-n-move-shaking {
  0% { transform: rotate(0deg); }
  25% { transform: rotate(5deg); }
  50% { transform:  rotate(0deg); }
  75% { transform:  rotate(-5deg); }
  100% { transform:  rotate(0deg); }
}

@keyframes rotateSpinner {
  to {
    transform: rotate(360deg);
  }
}

</style>
