<script setup lang="ts">
import { ref } from 'vue'

const filters = ref([
  {
    title: 'Новые поступления'
  },
  {
    title: 'Распродажа'
  },
  {
    title: 'Бренды'
  },
  {
    title: 'Байеры'
  },
  {
    title: 'Стоки'
  },
  {
    title: 'Одежда'
  },
  {
    title: 'Обувь'
  },
  {
    title: 'Сумки'
  },
  {
    title: 'Акессуары'
  },
  {
    title: 'Дом'
  }
])
</script>

<template>
  <div class="filters-menu__wrapper">
    <div
      v-for="item in filters"
      class="filters-item"
    >
      {{ item.title }}
    </div>
  </div>
</template>

<style scoped>
.filters-menu__wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  padding: 8px 100px;
  max-height: 60px;
  position: relative;
  top: -58px;
  flex-wrap: wrap;
  border-top-right-radius: 40px;
  border-top-left-radius: 40px;
  background: rgba(245, 245, 247, 1);
}

.filters-item {
  cursor: pointer;
  padding: 8px 24px;
  border: 1px solid rgba(215, 217, 219, 1);
  border-radius: 20px;
  color: rgba(61, 61, 61, 1);
}

@media screen and (max-width: 1024px) {
  .filters-menu__wrapper {
    display: none;
  }
}
</style>
