<template>
  <!-- Проверяем, существует ли целевой элемент -->
  <Teleport :to="targetElement" v-if="targetElement" :disabled="disabled">
    <!-- Передаем содержимое слота в телепорт -->
    <slot />
  </Teleport>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted, watch } from 'vue';

// Принимаем пропс с ID целевого элемента
const props = defineProps({
  targetId: {
    type: String,
    required: true,
  },
  disabled:{
    type:Boolean,
    required: true,
  }
});

// Создаем реактивную переменную для хранения целевого элемента
const targetElement = ref(null);

// Функция для поиска целевого элемента в DOM
const findTargetElement = () => {
  return document.querySelector(`#${props.targetId}`);
};

// Обновляем targetElement при монтировании компонента
onMounted(() => {
  targetElement.value = findTargetElement();

  // Если элемент не найден сразу, запускаем наблюдатель за DOM
  if (!targetElement.value && !props.disabled) {
    const observer = new MutationObserver(() => {
      const element = findTargetElement();
      if (element) {
        targetElement.value = element;
        observer.disconnect(); // Останавливаем наблюдение, если элемент найден
      }
    });

    // Начинаем наблюдение за изменениями в DOM
    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    // Останавливаем наблюдение при уничтожении компонента
    onUnmounted(() => {
      observer.disconnect();
    });
  }
});

// Следим за изменением пропса targetId
watch(
  () => props.targetId,
  () => {
    targetElement.value = findTargetElement();
  }
);
</script>