<template>
  <div class="home-page__wrapper">
    <header-search-block-wrapper class="mobile" />
    <home-page-header :is-mega-menu-visible-immediately="true" class="desktop-header" />
    <mobile-menu-and-header-wrapper class="mobile-header" />
    <div class="result-page-content-inner">
      <search-page-content-header :title="searchedText" :items-count="totalQuantity" />
      <search-page-content-wrapper />
    </div>
<!--    <subscribe-form />-->
    <base-footer />
  </div>
</template>

<script setup lang="ts">
import SearchPageContentWrapper from "@/blocks/SearchPage/SearchPageContent/SearchPageContentWrapper.vue";
import SearchPageContentHeader from "@/blocks/SearchPage/SearhPageContentHeader/SearchPageContentHeader.vue";
import SubscribeForm from "@/blocks/HomeWrapper/SubscribeForm/SubscribeForm.vue";
import BaseFooter from "@/blocks/SharedBlocks/Footer/Footer.vue";
import { computed, watch } from "vue";
import { useSearch } from "@/stores/search";
import { useRoute, useRouter } from "vue-router";
import { transformFilters } from "@/helpers/createFiltersArrayFromUserMenuForBackendApi";
import { transformFiltersToObjectQuery } from "@/helpers/transformSelectedFiltersToRouterQuerry";
import MobileMenuAndHeaderWrapper from '@/blocks/SharedBlocks/MobileMenuAndHeader/MobileMenuAndHeaderWrapper.vue'
import HomePageHeader from '@/blocks/SharedBlocks/DesktopHeader/HeaderWrapper.vue'
import HeaderSearchBlockWrapper from '@/blocks/SharedBlocks/HeaderSearchBlock/HeaderSearchBlockWrapper.vue'

const search = useSearch();
const route = useRoute();
const router = useRouter();

const searchedText = computed(() => route.params.searchText);
const currentFilters = computed(() => search.currentFilters);
const totalQuantity = computed(() => search.totalQuantity);
watch(
  currentFilters,
  async () => {
    const trnsfftsrs = transformFilters(currentFilters.value);
    const urlFilters = transformFiltersToObjectQuery(trnsfftsrs);
    if (route.query.page) {
      urlFilters.page = route.query.page;
    }

    await router.push({ query: urlFilters });

  },
  {
    deep: true,
  }
);
</script>

<style scoped>
.home-page__wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: rgba(245, 245, 247, 1);
}

.result-page-content-inner {
  padding: 0 100px 80px 100px;
}

.mobile-header {
  display: none;
}

.mobile {
  display: none;
}


@media (max-width: 1024px) {
  .result-page-content-inner {
    padding: 16px;
    margin-top: 20px;
  }

  .mobile {
    display: initial;
  }
}

@media (max-width: 1024px) {
  .mobile-header {
    display: initial;
    position: fixed;
    bottom:20px;
    width: 338px;
    z-index: 10;
    left:50%;
    transform: translate(-50%, 0);
  }
  .desktop-header {
    display: none;
  }
}

</style>
