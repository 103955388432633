<script setup lang="ts"></script>

<template>
  <div class="reset-password-success__content">
    <span class="reset-password-success__title title-big">Вы успешно сменили пароль</span>
  </div>
</template>
<style scoped>
.reset-password-success__content {
  display: flex;
  border-radius: 40px;
  padding: 40px;
  gap: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.reset-password-success__title {
  text-align: center;
}

.reset-password-success_third_party button {
  display: flex;
  border-radius: 8px;
  gap: 8px;
  padding: 12px 20px;
}
</style>
