<script setup lang="ts">
import Divider from 'primevue/divider'
import { ref } from 'vue'
import FooterDesktopContent from '@/blocks/SharedBlocks/Footer/FooterDesktopContent.vue'
import FooterMobileContent from '@/blocks/SharedBlocks/Footer/FooterMobileContent.vue'
import CustomSvgIcons from '@/components/HelpersComponents/CustomSvgIcons.vue'

//TODO вынести текст в константы, иконки вынести в компоненты отдельные посмотреть повтораяющиеся стили

const links = ref({
  'О НАС': [
    'О компании',
    'Контактная информация',
    'Гарантии',
    'Политика конфиденциальности',
    'Условия обслуживания'
  ],
  'ПОЛЬЗОВАТЕЛЯМ': [
    'FAQ (часто задаваемые вопросы)',
    'Помощь',
    'Рекомендации по ...',
    'Политика обработки cookies'
  ],
  'ДЛЯ БИЗНЕСА': ['Для бизнеса', 'Часы работы службы поддержки', 'Форма обратной связи']
})

const bottomText = ref({
  year: '2024-2024',
  companyName: 'ООО "Селектио"'
})
</script>

<template>
  <div class="footer__wrapper">
    <div class="footer-bottom__wrapper">
      <footer-desktop-content :footer-links-object="links"
                              class="desktop-footer"
      />
      <footer-mobile-content :footer-links-object="links" :bottom-text="bottomText"
                             class="mobile-footer"
      />
      <Divider class="hide" />
      <div class="footer-icons__wrapper">
        <div class="pointer">
          <CustomSvgIcons name="telegram" :size="20" />
        </div>
        <div class="pointer">
          <CustomSvgIcons name="vk" :size="20" />
        </div>
        <div class="pointer">
          <CustomSvgIcons name="instagram" :size="20" />
        </div>
        <div class="pointer">
          <CustomSvgIcons name="tiktok" :size="20" />
        </div>
      </div>
      <p class="bottom-text hide">
        <span>{{ bottomText.year }}</span>
        <span>{{ bottomText.companyName }}</span>
      </p>
    </div>
  </div>
</template>

<style scoped>
.pointer {
  cursor: pointer;
}

.footer__wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: rgba(36, 49, 74, 1);
  border-top-right-radius: 40px;
  border-top-left-radius: 40px;
  z-index: 5;
}

.footer-bottom__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
}

.footer-icons__wrapper {
  display: flex;
  gap: 12px;
  margin-top: 52px;
}

.bottom-text {
  display: flex;
  flex-direction: column;
  color: rgba(145, 145, 145, 1);
  margin-top: 40px;
  padding-bottom: 26px;
}


.mobile-footer {
  display: none !important;
}

@media (max-width: 1024px) {
  .mobile-footer {
    display: initial!important;
  }
  .desktop-footer {
    display: none!important;
  }

  .hide {
    display: none;
  }

  .footer-icons__wrapper {
    width:78%;
    margin-top: 0;
    margin-bottom: 80px;
  }
}
</style>
