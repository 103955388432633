<script setup lang="ts">
import { onMounted, ref, watch } from 'vue'
import { getBrands, getBrandsCategories } from '@/api/brands'
import type { FilterItemOptions } from '@/types/types'

//TODO пока не знаю что сделать здесь

const { brandName } = defineProps<{
  brandName?: string
}>()

const categories = ref([])
const searchValue = ref('')
const brands = ref({})
const selectedCategory = ref<string[]>([])
const sort = ref<'asc' | 'desc'>('asc')

function changeSort() {
  sort.value = sort.value === 'asc' ? 'desc' : 'asc'
}

function setToSelectedCategory(name: string) {
  selectedCategory.value.push(name)
}

onMounted(async () => {
  await getBrands(searchValue.value, 'asc', undefined).then((res) => {
    brands.value = res.data.brands
  })

  await getBrandsCategories().then((res) => {
    const temp = res.data.categories
    temp.splice(10)
    categories.value = temp
  })
})

watch(
  () => [searchValue.value, selectedCategory.value, sort.value],
  async () => {
    await getBrands(searchValue.value, sort.value, selectedCategory.value).then((res) => {
      brands.value = res.data.brands
    })
  },
  {
    deep: true
  }
)
</script>

<template>
  <div class="brands__wrapper">
    {{ brandName }}
    <div class="brands_categories__wrapper">
      <div
        v-for="category in categories"
        class="brands_categories__item"
        @click="setToSelectedCategory(category)"
      >
        {{ category }}
      </div>
    </div>
    <div class="brands-search-wrapper">
      <input type="text" v-model="searchValue" />
      <span class="sort" @click="changeSort">{{ sort }}</span>
    </div>
    <div class="brands-items__wrapper">
      <div v-for="(brandCategory, key) of brands" class="brands-category__wrapper">
        <span class="brand_key">{{ key }}</span>
        <div v-for="brand in brandCategory">{{ brand.name }}</div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.brands__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
}

.brands_categories__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 90%;
  flex-wrap: wrap;
}

.brands-search-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.brands-items__wrapper {
  width: 90%;
  display: flex;
  justify-content: center;
  gap: 50px;
  flex-wrap: wrap;
}

.brand_key {
  font-weight: bold;
}

.sort {
  cursor: pointer;
}
</style>
