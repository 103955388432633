import './assets/main.css'
import 'primeicons/primeicons.css'
import 'virtual:svg-icons-register'
import Aura from '@primevue/themes/aura'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import PrimeVue from 'primevue/config'
import Tooltip from 'primevue/tooltip'
import ToastService from 'primevue/toastservice'
import ClickOutside from '@/directives/ClickOutsideDirective'
import MasonryWall from '@yeger/vue-masonry-wall'
import App from './App.vue'
import router from './router'
import { definePreset } from '@primevue/themes'
import { vCopy } from '@/directives/vCopy'

const app = createApp(App)

const MyPreset = definePreset(Aura, {
  components: {
    button: {
      root: {
        paddingX: '24px',
        paddingY: '16px',
        borderRadius: '40px'
      },

      colorScheme: {
        light: {
          root: {
            primary: {
              background: 'rgba(36, 49, 74, 1)',
              borderColor: 'rgba(36, 49, 74, 1)',
              hoverBackground: 'rgba(28, 38, 55, 1)',
              activeBackground: 'rgba(21, 28, 41, 1)',
              hoverBorderColor: 'rgba(28, 38, 55, 1)',
              activeBorderColor: 'rgba(21, 28, 41, 1)',
              color: 'rgba(255, 255, 255, 1)',
              hoverColor: 'rgba(255, 255, 255, 1)',
              activeColor: 'rgba(255, 255, 255, 1)'
            },
            secondary: {
              background: 'rgba(35, 47, 69, 0.2)',
              borderColor: 'rgba(35, 47, 69, 0.2)',
              hoverBackground: 'rgba(35, 47, 69, 0.4)',
              activeBackground: 'rgba(35, 47, 69, 0.6)',
              hoverBorderColor: 'rgba(35, 47, 69, 0.4)',
              activeBorderColor: 'rgba(35, 47, 69, 0.6)',
              color: 'rgba(35, 47, 69, 1)',
              hoverColor: 'rgba(35, 47, 69, 1)',
              activeColor: 'rgba(35, 47, 69, 1)'
            },
            info: {
              background: 'transparent',
              borderColor: 'transparent',
              hoverBackground: 'rgba(35, 47, 69, 0.1)',
              activeBackground: 'rgba(35, 47, 69, 0.2)',
              hoverBorderColor: 'rgba(35, 47, 69, 0.1)',
              activeBorderColor: 'rgba(35, 47, 69, 0.2)',
              color: 'rgba(35, 47, 69, 0.8)',
              hoverColor: 'rgba(35, 47, 69, 0.8)',
              activeColor: 'rgba(35, 47, 69, 0.8)'
            },
            warn: {
              background: 'transparent',
              hoverBackground: 'rgba(35, 47, 69, 0.1)',
              activeBackground: 'rgba(35, 47, 69, 0.2)',
              borderColor: 'transparent',
              hoverBorderColor: 'rgba(35, 47, 69, 0.1)',
              activeBorderColor: 'rgba(35, 47, 69, 0.2)',
              color: 'rgba(110, 133, 203, 1)',
              hoverColor: 'rgba(110, 133, 203, 1)',
              activeColor: 'rgba(110, 133, 203, 1)'
            }
          },
          contrast: {
            background: 'rgba(255, 255, 255, 0.8)',
            hoverBackground: 'rgba(208, 211, 218, 1)',
            activeBackground: 'rgba(165, 170, 181, 1)',
            borderColor: 'rgba(255, 255, 255, 0.8)',
            hoverBorderColor: 'rgba(208, 211, 218, 1)',
            activeBorderColor: 'rgba(165, 170, 181, 1)',
            color: 'rgba(35, 47, 69, 1)',
            hoverColor: 'rgba(35, 47, 69, 1)',
            activeColor: 'rgba(35, 47, 69, 1)'
          },
          help: {
            background: 'rgba(110, 133, 203, 0.2)',
            hoverBackground: 'rgba(110, 133, 203, 0.4)',
            activeBackground: 'rgba(110, 133, 203, 0.6)',
            borderColor: 'rgba(110, 133, 203, 0.2)',
            hoverBorderColor: 'rgba(110, 133, 203, 0.4)',
            activeBorderColor: 'rgba(110, 133, 203, 0.6)',
            color: 'rgba(35, 47, 69, 1)',
            hoverColor: 'rgba(35, 47, 69, 1)',
            activeColor: 'rgba(35, 47, 69, 1)',
            focusRing: {
              color: 'rgba(162, 184, 233, 0.8)',
              shadow: 'none'
            }
          },
          outlined: {
          },
          text: {
            primary: {
              color: 'rgba(36, 49, 74, 1)',
            }
          }
        }
      }
    },
    inputtext: {
      root: {
        color: 'rgba(61, 61, 61, 1)',
        borderColor: 'rgba(145, 145, 145, 1)',
        hoverBorderColor: 'rgba(36, 49, 74, 1)',
        focusBorderColor: 'rgba(36, 49, 74, 1)',
        invalidBorderColor: 'rgba(186, 26, 26, 1)'
      }
    },
    floatlabel: {
      root: {
        color: 'rgba(61, 61, 61, 1)',
        focusColor: 'rgba(36, 49, 74, 1)',
        invalidColor: 'rgba(186, 26, 26, 1)'
      }
    },
    checkbox: {
      root: {
        width: '24px',
        height: '24px',
        checkedBackground: 'rgba(36, 49, 74, 1)',
        borderColor: 'rgba(36, 49, 74, 1)',
        checkedHoverBackground: 'rgb(80, 90, 110)',
        checkedBorderColor: 'rgba(36, 49, 74, 1)',
        checkedHoverBorderColor: 'rgb(80, 90, 110)'
      }
    },
    slider: {
      handle: {
        content: {
          hoverBackground: 'rgba(36, 49, 74, 1)'
        },
        focusRing: {
          color: 'rgba(36, 49, 74, 1)'
        }
      },
      colorScheme: {
        light: {
          handle: {
            contentBackground: 'rgba(36, 49, 74, 1)'
          }
        },
        dark: {
          handle: {
            contentBackground: 'rgba(36, 49, 74, 1)'
          }
        }
      }
    },
    paginator: {
      root: {
        padding: '0.5rem 1rem',
        gap: '8px',
        borderRadius: '0px',
        background: 'transparent',
        color: 'rgba(36, 49, 74, 1)'
      },
      navButton: {
        background: 'transparent',
        hoverBackground: 'rgba(215, 217, 219, 1)',
        selectedBackground: 'rgba(36, 49, 74, 1)',
        color: 'rgba(36, 49, 74, 1)',
        hoverColor: 'rgba(36, 49, 74, 1)',
        selectedColor: 'rgba(255, 255, 255, 1)',
        width: '44px',
        height: '40px',
        borderRadius: '8px',
        focusRing: {
          width: '54px'
        }
      },
      currentPageReport: {
        color: '{text.muted.color}'
      },
      jumpToPageInput: {
        maxWidth: '2.5rem'
      }
    },
    toggleswitch: {
      root: {
        width: '40px',
        height: '24px',
        borderRadius: '75px',
        borderWidth: '0.1rem',
        hoverBorderColor: 'rgba(36, 49, 74, 1)',
        borderColor: 'rgba(36, 49, 74, 1)',
        gap: '0.25rem',
      },
      handle: {
        borderRadius: '50%',
        size: '12px',
      },
      colorScheme: {
        light: {
          root: {
            background: '#fff',
            hoverBackground: '#fff',
            checkedBackground: 'rgba(36, 49, 74, 1)',
            checkedHoverBackground: 'rgba(36, 49, 74, 1)'
          },
          handle: {
            background: 'rgba(36, 49, 74, 1)',
            hoverBackground: 'rgba(36, 49, 74, 1)',
            checkedBackground: '#fff',
            checkedHoverBackground: '#fff',
          }
        },
      }
    },
    listbox: {
      option: {
        selectedBackground: 'transparent',
        selectedFocusBackground: 'transparent',
        selectedColor: 'rgba(61, 61, 61, 1)',
        selectedFocusColor: 'rgba(61, 61, 61, 1)',
        focusBackground: 'rgba(215, 217, 219, 1)',
      }
    },
    chip: {
      root: {
        borderRadius: '16px',
        paddingX: '12px',
        paddingY: '4px',
        gap: '0.5rem',
        transitionDuration: '{transition.duration}'
      },
      colorScheme: {
        light: {
          root: {
            background: 'rgba(255, 255, 255, 1)',
            color: 'rgba(61, 61, 61, 1)'
          },
        },
        dark: {
          root: {
            background: 'rgba(255, 255, 255, 1)',
            color: 'rgba(61, 61, 61, 1)'
          },
        }
      }
    },
    message: {
      root: {
        borderRadius: '{content.border.radius}',
        borderWidth: '1px',
        transitionDuration: '{transition.duration}'
      },
      content: {
        padding: '0.5rem 0.75rem',
        gap: '0.5rem',
        sm: {
          padding: '0.375rem 0.625rem'
        },
        lg: {
          padding: '0.625rem 0.875rem'
        }
      },
      text: {
        fontSize: '1rem',
        fontWeight: '500',
        sm: {
          fontSize: '0.875rem'
        },
        lg: {
          fontSize: '1.125rem'
        }
      },
      icon: {
        size: '1.125rem',
        sm: {
          size: '1rem'
        },
        lg: {
          size: '1.25rem'
        }
      },
      closeButton: {
        width: '1.75rem',
        height: '1.75rem',
        borderRadius: '50%',
        focusRing: {
          width: '{focus.ring.width}',
          style: '{focus.ring.style}',
          offset: '{focus.ring.offset}'
        }
      },
      closeIcon: {
        size: '1rem',
        sm: {
          size: '0.875rem'
        },
        lg: {
          size: '1.125rem'
        }
      },
      outlined: {
        root: {
          borderWidth: '1px'
        }
      },
      simple: {
        content: {
          padding: '0'
        }
      },
      colorScheme: {
        light: {
          error: {
            background: 'color-mix(in srgb, {red.50}, transparent 5%)',
            borderColor: '{red.200}',
            color: '{red.600}',
            shadow: '0px 4px 8px 0px color-mix(in srgb, {red.500}, transparent 96%)',
            closeButton: {
              hoverBackground: '{red.100}',
              focusRing: {
                color: '{red.600}',
                shadow: 'none'
              }
            },
            outlined: {
              color: '{red.600}',
              borderColor: '{red.600}'
            },
            simple: {
              color: '{red.600}'
            }
          },
          secondary: {
            background: '{surface.100}',
            borderColor: '{surface.200}',
            color: '{surface.600}',
            shadow: '0px 4px 8px 0px color-mix(in srgb, {surface.500}, transparent 96%)',
            closeButton: {
              hoverBackground: '{surface.200}',
              focusRing: {
                color: '{surface.600}',
                shadow: 'none'
              }
            },
            outlined: {
              color: '{surface.500}',
              borderColor: '{surface.500}'
            },
            simple: {
              color: '{surface.500}'
            }
          },
          contrast: {
            background: '{surface.900}',
            borderColor: '{surface.950}',
            color: '{surface.50}',
            shadow: '0px 4px 8px 0px color-mix(in srgb, {surface.950}, transparent 96%)',
            closeButton: {
              hoverBackground: '{surface.800}',
              focusRing: {
                color: '{surface.50}',
                shadow: 'none'
              }
            },
            outlined: {
              color: '{surface.950}',
              borderColor: '{surface.950}'
            },
            simple: {
              color: '{surface.950}'
            }
          }
        },
        dark: {
          info: {
            background: 'color-mix(in srgb, {blue.500}, transparent 84%)',
            borderColor: 'color-mix(in srgb, {blue.700}, transparent 64%)',
            color: '{blue.500}',
            shadow: '0px 4px 8px 0px color-mix(in srgb, {blue.500}, transparent 96%)',
            closeButton: {
              hoverBackground: 'rgba(255, 255, 255, 0.05)',
              focusRing: {
                color: '{blue.500}',
                shadow: 'none'
              }
            },
            outlined: {
              color: '{blue.500}',
              borderColor: '{blue.500}'
            },
            simple: {
              color: '{blue.500}'
            }
          },
          success: {
            background: 'color-mix(in srgb, {green.500}, transparent 84%)',
            borderColor: 'color-mix(in srgb, {green.700}, transparent 64%)',
            color: '{green.500}',
            shadow: '0px 4px 8px 0px color-mix(in srgb, {green.500}, transparent 96%)',
            closeButton: {
              hoverBackground: 'rgba(255, 255, 255, 0.05)',
              focusRing: {
                color: '{green.500}',
                shadow: 'none'
              }
            },
            outlined: {
              color: '{green.500}',
              borderColor: '{green.500}'
            },
            simple: {
              color: '{green.500}'
            }
          },
          warn: {
            background: 'color-mix(in srgb, {yellow.500}, transparent 84%)',
            borderColor: 'color-mix(in srgb, {yellow.700}, transparent 64%)',
            color: '{yellow.500}',
            shadow: '0px 4px 8px 0px color-mix(in srgb, {yellow.500}, transparent 96%)',
            closeButton: {
              hoverBackground: 'rgba(255, 255, 255, 0.05)',
              focusRing: {
                color: '{yellow.500}',
                shadow: 'none'
              }
            },
            outlined: {
              color: '{yellow.500}',
              borderColor: '{yellow.500}'
            },
            simple: {
              color: '{yellow.500}'
            }
          },
          error: {
            background: 'color-mix(in srgb, {red.500}, transparent 84%)',
            borderColor: 'color-mix(in srgb, {red.700}, transparent 64%)',
            color: '{red.500}',
            shadow: '0px 4px 8px 0px color-mix(in srgb, {red.500}, transparent 96%)',
            closeButton: {
              hoverBackground: 'rgba(255, 255, 255, 0.05)',
              focusRing: {
                color: '{red.500}',
                shadow: 'none'
              }
            },
            outlined: {
              color: '{red.500}',
              borderColor: '{red.500}'
            },
            simple: {
              color: '{red.500}'
            }
          },
          secondary: {
            background: '{surface.800}',
            borderColor: '{surface.700}',
            color: '{surface.300}',
            shadow: '0px 4px 8px 0px color-mix(in srgb, {surface.500}, transparent 96%)',
            closeButton: {
              hoverBackground: '{surface.700}',
              focusRing: {
                color: '{surface.300}',
                shadow: 'none'
              }
            },
            outlined: {
              color: '{surface.400}',
              borderColor: '{surface.400}'
            },
            simple: {
              color: '{surface.400}'
            }
          },
          contrast: {
            background: '{surface.0}',
            borderColor: '{surface.100}',
            color: '{surface.950}',
            shadow: '0px 4px 8px 0px color-mix(in srgb, {surface.950}, transparent 96%)',
            closeButton: {
              hoverBackground: '{surface.100}',
              focusRing: {
                color: '{surface.950}',
                shadow: 'none'
              }
            },
            outlined: {
              color: '{surface.0}',
              borderColor: '{surface.0}'
            },
            simple: {
              color: '{surface.0}'
            }
          }
        }
      }
    },
    rating: {
      icon: {
        size: '1rem',
        color: '#FFA500',
        hoverColor: '#FFA500',
        activeColor: '#FFA500'
      }
    },
    galleria: {
      indicatorButton: {
        width: '1rem',
        height: '1rem',
        activeBackground: 'rgba(110, 133, 203, 1)',
        borderRadius: '50%',
      },
      insetIndicatorList: {
        background: 'transparent'
      },
      insetIndicatorButton: {
        background: 'rgba(255, 255, 255, 0.2)',
        hoverBackground: 'rgba(255, 255, 255, 0.6)',
        activeBackground: 'rgba(110, 133, 203, 1)'
      },
    },
    accordion:{
      panel: {
        borderColor: 'rgba(255, 255, 255, 0.2)'
      },
      header: {
        background: 'transparent',
        hoverBackground: 'transparent',
        activeBackground: 'transparent',
        activeHoverBackground: 'transparent',
        toggleIcon: {
          color: 'rgba(255, 255, 255, 0.6)',
          hoverColor: 'rgba(255, 255, 255, 0.6)',
          activeColor: 'rgba(255, 255, 255, 0.6)',
          activeHoverColor: 'rgba(255, 255, 255, 0.6)'
        },
      },
      content: {
        background: 'transparent',
      }
    }
  }
})

app.use(ToastService)
app.use(createPinia())
app.use(router)
app.use(PrimeVue, {
  theme: {
    preset: MyPreset,
    options: {
      darkModeSelector: '.fake-dark-selector', // trying to also force a non-usage of the dark mode
      cssLayer: {
        name: 'primevue',
        // Enable PrimeVue CSS layer and configure the tailwind styles to have higher specificity with layering
        order: 'tailwind-base, primevue, tailwind-utilities',
      },
    },
  }
})
app.use(MasonryWall)
app.directive('tooltip', Tooltip)
app.directive('click-outside', ClickOutside)
app.directive('copy', vCopy)
app.mount('#app')
