import type { DynamicQueryFilters } from '@/types/queryTypes'
import type { LocationQuery } from 'vue-router'


export function parseFiltersFromUrl(queryObject: LocationQuery):any[] {
  const filters = [];

  for (const key in queryObject) {
    const value = queryObject[key];

    if (value !== null) {
      if(key === 'page') {
        continue
      }

      if (key === "Price" && Array.isArray(value)) {
        const [gte,lte] = value.map(Number);
        filters.push({
          filter_type: key.toLocaleLowerCase(),
          values: { lte, gte }
        });
      } else if (Array.isArray(value)) {
        filters.push({
          filter_type: key.toLocaleLowerCase(),
          values: value
        });
      } else if (typeof value === 'string') {
        filters.push({
          filter_type: key.toLocaleLowerCase(),
          values: [value]
        });
      }
    }
  }

  return filters ;
}