import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/pages/HomePage.vue'
import SearchView from '@/pages/SearchPage.vue'
import {
  COMPARE_ROUTE,
  FAVORITES_ROUTE,
  HOME_ROUTE,
  PROFILE_ROUTE,
  STOCK_ROUTE,
  USED_ROUTE
} from '@/router/routes'
import Brands from '@/blocks/Brands/Brands.vue'
import BannersList from '@/blocks/Recommendations/BannersList.vue'
import CollectionsList from '@/blocks/Recommendations/CollectionsList.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: HOME_ROUTE,
      name: 'home',
      children: [
        {
          name: 'start',
          path: '',
          component: HomeView
        },
        {
          path: '/?:audience',
          component: HomeView
        },
        {
          path: '/verifyuser/:verifyToken',
          component: HomeView
        },
        {
          path: '/resetpassword/:resetToken',
          component: HomeView
        },
        {
          path: '/home',
          component: HomeView
        },
        {
          path: '/login',
          component: HomeView
        },
        {
          path: '/brands',
          component: Brands,

        },
        {
          path: '/brands/:brandName',
          component: Brands, // Маршрут для /brands с параметром brandName
          props: true
        }
      ]
    },
    {
      path: PROFILE_ROUTE,
      name: 'profile',
      component: () => import('@/pages/ProfilePage.vue')
    },
    {
      path: FAVORITES_ROUTE,
      name: 'favorites',
      component: () => import('@/pages/FavoritesPage.vue')
    },
    {
      path: '/recommendations',
      name: 'recommendations',
      children: [
        {
          path: 'banners/:bannerId(\\d+)',
          component: BannersList
        },
        {
          path: '/collections/:collectionsId(\\d+)',
          component: CollectionsList
        }
      ]
    },
    {
      path: '/searchList',
      children: [
        {
          path: ':searchText?',
          component: SearchView
        }
      ]
    },
    {
      path: `/product=:productId(\\d+)/variant=:productVariantId(\\d+)`,
      name: 'product',
      component: () => import('../pages/ProductPage.vue')
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    if (to.path.includes('/searchList')) {
      return { top: 0 };
    }
    // Прокрутить вверх при каждом переходе

  },
})

export default router
