import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import { setFilters, filtersList, shoppingList } from '@/api/search'
import type { arrayOfFilters, FilterItem, Pagination, staticFilters } from '@/types/types'
import type { shoppingListParams, shoppingListPayload, SortingOptions } from '@/types/searchItemsTypes'
import { useRoute } from 'vue-router'
import { updateObjects, type Obj1 } from '@/helpers/createFiltersStateFromCombinetWithUrlState'

export const useSearch = defineStore('search', () => {


  const route = useRoute()

  const defineFiltersError = ref()
  const isDefineFiltersLoading = ref(false)

  const getSearchListError = ref()
  const isGetSearchListLoading = ref(false)

  const suggestedFilters = ref([])
  const currentFilters = ref<arrayOfFilters>([])
  const staticFilters = ref<staticFilters>({
    original: false,
    high_rating: false
  })


  const responseDefineFilters = ref([])
  const countOfItemInRow = ref(4)
  const currentSort = ref<SortingOptions>('default')
  const pageInfo = ref<Pagination>()
  const itemsBehavior = ref('default')
  const searchResult = ref([])
  const totalQuantity = ref(0)
  const generatedImageLink = ref()

  const defineFilters = async (searchText: string) => {
    defineFiltersError.value = ''
    isDefineFiltersLoading.value = true
    try {
      return setFilters(searchText).then((res) => {
        responseDefineFilters.value = res.data.defined_filters
        isDefineFiltersLoading.value = false
        return res
      })
    } catch (err) {
      isDefineFiltersLoading.value = false
      defineFiltersError.value = err
      throw err
    } finally {
    }
  }

  const getSearchList = async (payload: shoppingListPayload, params: shoppingListParams) => {
    getSearchListError.value = ''
    isGetSearchListLoading.value = true
    try {
      return shoppingList(payload, params).then(res => {
        const filtersUpdatedByUrl = updateObjects(route.query as Obj1, res.data.dynamic_filters)
        suggestedFilters.value = res.data.suggested_filters
        pageInfo.value = res.data.page_info
        searchResult.value = res.data.search_results
        currentFilters.value = filtersUpdatedByUrl
        totalQuantity.value = res.data.total_quantity
        generatedImageLink.value = res.data.generated_image_url
        staticFilters.value = res.data.static_filters
        isGetSearchListLoading.value = false
        return res
      })
    } catch (err) {
      isGetSearchListLoading.value = false
      getSearchListError.value = err
      throw err
    } finally {
    }
  }


  const setCheckBoxValueState = (id: number, value: boolean) => {
    if (currentFilters.value)
      for (let obj of currentFilters.value) {
        if (obj.options.values)
          for (let item of obj.options.values) {
            if (item.id === id) {
              item.isSelected = value
            }
          }
      }
  }

  const changePriceFromUserMenu = async (min: number, max: number, indexOfCategory: number) => {
    if (currentFilters.value) {
      currentFilters.value[indexOfCategory].options.min_filter_value = min
      currentFilters.value[indexOfCategory].options.max_filter_value = max
    }
  }


  const clearAllFilters = async () => {
    currentFilters.value?.forEach((currentFiltersItem) => {
      currentFiltersItem.options.values?.forEach((item) => {
        item.isSelected = false
      })
    })
  }

  const clearFiltersToSelectedFilterType = async (item: FilterItem) => {
    const selectedItem = currentFilters.value?.find((currentFiltersItem) => currentFiltersItem.options.label === item?.label || currentFiltersItem.options.label === item.options?.label)
    if (!selectedItem) return
    selectedItem?.options.values?.forEach((item) => {
      item.isSelected = false
    })
  }

  const setItemsBehavior = async (behavior: string) => {
    itemsBehavior.value = behavior
  }

  const setSortingOptions = (sort: SortingOptions) => {
    currentSort.value = sort
  }

  return {
    getSearchList,
    defineFilters,
    currentFilters,
    staticFilters,
    responseDefineFilters,
    countOfItemInRow,
    currentSort,
    suggestedFilters,
    setCheckBoxValueState,
    changePriceFromUserMenu,
    clearAllFilters,
    clearFiltersToSelectedFilterType,
    pageInfo,
    itemsBehavior,
    setItemsBehavior,
    setSortingOptions,
    searchResult,
    totalQuantity,
    generatedImageLink,
    isDefineFiltersLoading,
    isGetSearchListLoading,
  }
})