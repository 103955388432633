<script setup lang="ts">
import BannersText from '@/blocks/HomeWrapper/Banners/BannersText.vue'
import BannersList from '@/blocks/HomeWrapper/Banners/BannersList.vue'
import { getRecommendationsBanners } from '@/api/recommendations'
import { ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import type { RecommendedBanner } from '@/types/BannersTypes'
import DeferredContent from 'primevue/deferredcontent'

import {
  Autoplay,
  Controller,
  FreeMode,
  Navigation,
  Virtual,
  Pagination,
} from "swiper/modules";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { Swiper, SwiperSlide, useSwiper } from "swiper/vue";
import AiTipForCapsules from '@/blocks/HomeWrapper/Capsules/AiTipForCapsules.vue'
import BannerTextContentWithTest from '@/blocks/HomeWrapper/Banners/BannerTextContentWithTest.vue'

const router = useRouter();
const route = useRoute();

const swiperModules = [FreeMode, Autoplay, Navigation, Controller, Virtual, Pagination];
const isBannersLoading = ref(false);

const bannersData = ref<RecommendedBanner[]>([]);

function handleBannerClick(bannerId:number) {
  router.push({
    path: `recommendations/banners/${bannerId}`,
  });
}

async function loadBannersData() {
  isBannersLoading.value = true;

  const res = await getRecommendationsBanners(
    route.query.audience ? route.query.audience : "woman"
  );
  bannersData.value = res.data.data;
  isBannersLoading.value = false;
}

watch(
  route,
  async () => {
    if (route.query.audience) {
      await loadBannersData();
    }
  },
  { deep: true }
);

</script>
<template>
  <DeferredContent @load="loadBannersData">
  <div class="recommendation_content_wrapper desktop">
    <banners-text />
    <banners-list
      :bannersData="bannersData"
      :is-banners-loading="isBannersLoading"
      @trigger-load-banners-data="loadBannersData"
      @trigger-banner-click="handleBannerClick"
    />
  </div>
  </DeferredContent>
  <div class="mobile">
    <swiper
      :free-mode="true"
      :space-between="20"
      :slides-offset-after="20"
      :pagination="{
            clickable: true,
          }"
      :breakpoints="{
            360: {
              slidesPerView: 1.25,
            },
            768:{
              slidesPerView: 2.5,
            },
            1024: {
              slidesPerView: 3,
            }
          }"
      :modules="swiperModules"
      class="mobile-banners-container"
    >
    <swiper-slide
      v-for="(banner, index) in bannersData"
      :key="index"
      class="company-carousel__item"
    >
      <div
        class="left-banner-item"
      >
        <div class="wrapper-for-ai-tips">
          <ai-tip-for-capsules>
            <span>{{ banner.ai_notes[0] }}</span>
          </ai-tip-for-capsules>
        </div>
        <div class="banners-item-image">
          <img :src="banner.image_url" />
        </div>
        <div class="banners-content">
          <div class="banners-item-title title-big">
            {{ banner.name }}
            <i class="pi pi-arrow-right" />
          </div>
          <p class="banners-text body-text">
            {{ banner.description }}
          </p>
        </div>
      </div>
    </swiper-slide>
    </swiper>
    <banner-text-content-with-test
      title="Define your style"
      description="Find out your taste preferences"
    />
  </div>
</template>

<style scoped>
.recommendation_content_wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 60px;
  border-radius: 40px;
  padding: 124px 60px 142px 60px;
}

.mobile {
  display: none;
}

.company-carousel__item {
  max-height: 550px;
  padding-bottom: 50px;
}

.adv-images__wrapper {
  position: relative;
}


.wrapper-for-ai-tips {
  width: 100%;
  height: 100%;
  padding: 30px;
  position: absolute;
}

.mobile-banners-container {
  width:90%;
}

.banners-left-items__wrapper {
  display: flex;
  justify-content: space-around;
  width: 50%;
  flex-wrap: wrap;
  gap: 80px;
}

.left-banner-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  max-height: 450px;
  gap: 12px;
  cursor: pointer;
}

.banners-item-image {
  width: 100%;
  height:450px;
  border-radius: 40px;
  overflow: hidden;
}

.banners-item-image img {
  object-fit: cover;
  height:100%;
  width:100%;
}

.banners-content {
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  align-items: flex-start;
  position: absolute;
  bottom: 0;
}

.banners-item-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px;
  color: white;
}

.banners-text {
  padding: 0 20px;
  color: white;
}

:deep(.swiper-pagination-bullet) {
  width: 24px;
  height: 4px;
  border-radius: 100px;
}

@media (max-width: 1024px) {
  .desktop {
    display: none;
  }

  .mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

</style>
