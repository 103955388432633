<script setup lang="ts">
import Button from "primevue/button";
import { computed, onMounted, onUpdated, ref, watch } from 'vue'
import { getRecommendationsBanners } from "@/api/recommendations";
import { useRoute, useRouter } from "vue-router";
import BannersListSkeleton from "@/blocks/HomeWrapper/Banners/BannersListSkeleton.vue";
import DeferredContent from "primevue/deferredcontent";
import AiTipForCapsules from "../Capsules/AiTipForCapsules.vue";
import YellowAiTip from "@/blocks/SharedBlocks/YellowAiTip.vue";
import type { ProductPriceChangeChart } from '@/types/ProductType'
import type { RecommendedBanner } from '@/types/BannersTypes'
import BannerTextContentWithTest from '@/blocks/HomeWrapper/Banners/BannerTextContentWithTest.vue'


const { bannersData } = defineProps<{
  bannersData: RecommendedBanner[],
  isBannersLoading: boolean
}>()

const emit = defineEmits<{
  (e: 'triggerBannerClick', id:number): void
}>()

</script>

<template>
    <div class="banners-wrapper">
      <banners-list-skeleton v-if="isBannersLoading" />
      <template v-else>
        <div class="banners-left-items__wrapper">
          <div
            v-for="item in bannersData.slice(0,4)"
            class="left-banner-item"
            @click="emit('triggerBannerClick',item.id)"
          >
            <div class="wrapper-for-ai-tips">
              <ai-tip-for-capsules>
                <span>{{ item.ai_notes[0] }}</span>
              </ai-tip-for-capsules>
            </div>
            <div class="banners-item-image">
              <img :src="item.image_url" />
            </div>
            <div class="banners-content">
              <div class="banners-item-title title-big">
                {{ item.name }}
                <i class="pi pi-arrow-right" />
              </div>
              <p class="banners-text body-text">
                {{ item.description }}
              </p>
            </div>
          </div>
        </div>

        <div class="banners-right-items__wrapper">
          <div class="right-item__wrapper">
            <div
              class="right-banner-image-wrapper"
              @click="emit('triggerBannerClick',bannersData[bannersData.length - 1]?.id)"
            >
              <img :src="bannersData[bannersData.length - 1]?.image_url" />
              <div class="right-banner-text title-big">
                <p class="txt">
                  {{ bannersData[bannersData.length - 1]?.name.toUpperCase() }}
                  <i class="pi pi-arrow-right" style="font-size: 1.5rem" />
                </p>
              </div>
            </div>
            <banner-text-content-with-test
              title="Define your style"
              description="Let our AI stylist create even more accurate and personalized
                      selections for you. Take the test so that we can better understand
                      your taste and preferences." />
          </div>
        </div>
      </template>
    </div>
</template>

<style scoped>
.right-banner-image-wrapper img {
  width: 100%;
  height: 100%;
}

.banners-item-image img {
  width: 100%;
  height: 100%;
}

.wrapper-for-ai-tips {
  width: 100%;
  height: 100%;
  padding: 30px;
  position: absolute;
}

.banners-left-items__wrapper {
  display: flex;
  justify-content: space-around;
  width: 50%;
  flex-wrap: wrap;
  gap: 40px;
}

.left-banner-item {
  display: flex;
  flex-direction: column;
  width: calc(100% / 2 - 40px);
  position: relative;
  gap: 12px;
  cursor: pointer;
}

.banners-item-image {
  max-width: 384px;
  max-height: 464px;
  height: 464px;
  border-radius: 40px;
  overflow: hidden;
  width: 100%;
}

.banners-content {
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  align-items: flex-start;
}

.banners-item-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px;
  color: rgba(61, 61, 61, 1);
}

.banners-text {
  padding: 0 20px;
  color: rgba(115, 115, 115, 1);
}

.banners-right-items__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(50% - 40px);
}

.right-item__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  width: 100%;
}

.right-banner-image-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;
  height: 860px;
  border-radius: 40px;
  background: grey;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.right-banner-text {
  display: flex;
  justify-content: center;
  position: absolute;
  align-items: center;
  color: rgba(255, 255, 255, 1);
}


.txt {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.banners-wrapper {
  display: flex;
  justify-content: center;
  gap: 40px;
}

@media (max-width: 1366px) {
  .banners-left-items__wrapper {
    width: 100%;
  }

  .banners-right-items__wrapper {
    width: 100%;
  }

  .right-banner-image-wrapper {
    max-height: 280px;
  }

  .right-banner-image-wrapper img {
    object-fit: cover;
  }
}
</style>
