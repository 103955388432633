<template>
  <div class="dock-window" >
    <Dock :model="items" :position="'bottom'"
          :pt="{
            listContainer:{
              style:{
                background:'rgba(35, 47, 69, 0.9)',
                borderColor:'rgba(35, 47, 69, 0.9)',
                padding:'8px',
                height:'80px',
                borderRadius:'1000px'
              }
            },
            item:{
              style:{
                width:'64px',
                height:'64px',
                padding:'0',
                borderRadius:'50%',
              }
            },
            itemLink:{
              style:{
                width:'100%',
                height:'64px',
              }
            }
          }"
    >
      <template #itemicon="{ item }">
        <div class="mobile-menu-buttons-item"
            :class="item.buttonCustomClass"
        >
          <i :class="`pi ${item.icon}`" style="font-size: 22px" />
          <span v-if="item.label !== 'Center'">{{item.label}}</span>
        </div>
      </template>
    </Dock>
  </div>
</template>

<script setup lang="ts">
import Dock from 'primevue/dock';

import { ref } from "vue";

const items = ref([
  {
    label: 'Home',
    icon: 'pi-home',
    buttonCustomClass: 'custom',
  },
  {
    label: 'Search',
    icon: 'pi-home',
    buttonCustomClass: 'custom',
  },
  {
    label: 'Center',
    icon: 'pi-plus',
    buttonCustomClass: 'centerIconClass',
  },
  {
    label: 'Mine',
    icon: 'pi-home',
    buttonCustomClass: 'custom',
  },
  {
    label: 'Profile',
    icon: 'pi-home',
    buttonCustomClass: 'custom',
  }
]);


</script>

<style scoped>
.mobile-menu-buttons-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  padding:12px;
  border-radius: 50%;
  color:rgba(255, 255, 255, 0.4);
  cursor:pointer;
  font-size: 10px;
  font-weight: 500;
  width:100%;
  height:100%;
}

.centerIconClass {
  display: flex;
  align-items: center;
  justify-content: center;
  border:1px solid rgba(216, 229, 173, 1);
  color: rgba(216, 229, 173, 1);
  width:100%;
  height:100%;
  background: rgba(110, 133, 203, 0.2);
}

</style>