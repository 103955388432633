<template>
  <div class="product-card">
    <img :src="image" :alt="title" class="product-image" />
    <div class="product-info">
      <h3 class="brand caption-text">{{ brand }}</h3>
      <p class="title caption-text">{{ title }}</p>
      <div class="price-container">
        <span class="price-from caption-text ">from</span>
        <span class="price body-text">{{ price }}$</span>
      </div>
    </div>
    <button @click="productClickHandler(idOfProduct,idOfVariant)" class="next-button" aria-label="Next" >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="black"
        class="icon"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M8.25 4.5l7.5 7.5-7.5 7.5"
        />
      </svg>
    </button>
  </div>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router'


const { brand, title, price, image } = defineProps<{
  brand: string
  title: string
  price: number
  image: string
  idOfProduct:number
  idOfVariant:number
}>()

const router = useRouter()

function productClickHandler(id: number,variantId:number) {
  router.push({ path: `/product=${id}/variant=${variantId}` });
}
</script>

<style scoped>
.product-card {
  display: flex;
  align-items: flex-end;
  gap: 16px;
  width: 100%;
  max-width: 400px;
  cursor: pointer;
  background-color: white;
  border-radius: 34px;
}

.product-image {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 4px;
}

.product-info {
  flex: 1;
  min-width: 0;
}

.brand {
  color: #6b7280;
  margin: 0 0 4px 0;
}

.title {
  color: #111827;
  margin: 0 0 4px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.price-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.price-from {
  color: #6b7280;
}

.price {
  font-weight: 600;
  margin-left: 4px;
}

.next-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(110, 133, 203, 0.2);
  border-radius: 50%;
  height: 48px;
  width: 48px;
  border: none;
  padding: 8px;
  cursor: pointer;
}

.icon {
  width: 20px;
  height: 20px;
  color: #9ca3af;
}
</style>
