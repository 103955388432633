<template>
  <div class="card flex justify-content-center">
    <SearchImageWrapper
      :base64data="base64data"
      @show-modal="imageSearch.isImageSearchModalOpen = true"
    />

    <Dialog
      ref="dialogFileUpload"
      :header="currentModalContentSteps.step1.active ? ' ' : 'Select area'"
      v-model:visible="imageSearch.isImageSearchModalOpen"
      :pt="{
        root: {
          style: {
            border: '20px',
            borderColor: 'transparent',
            background: 'white',
            borderRadius: '20px',
            maxHeight: '98%',
            height: 'fit-content',
          },
        },
        header:{
          style:{
            borderColor: 'transparent',
            background: 'white',
            padding:'20px',
            borderRadius: '20px',
          }
        },
        mask: {
          style: 'backdrop-filter: blur(2px)',
        },
        content: {
          style: {
            padding:0,
          },
        },
      }"
    >
        <DropImageModalContent
          v-if="currentModalContentSteps.step1.active"
          :base64data="base64data"
          @show-modal="imageSearch.isImageSearchModalOpen = true"
          @set-base64-image-data="handlerBase64Data"
          @custom-upload-image="customBase64Uploader"
        />
        <div v-if="currentModalContentSteps.step2.active"
             class="select-and-upload-image-content"
        >
          <div class="select-and-upload-image-content-cropper-wrapper">
            <temp-image-selection
              :image-height="imageHeightBasedOnScreen"
              :image-width="560"
              :current-image-link="base64data"
              @set-new-image-link="cropImageHandler"
              @get-back-to-previous-step="handleBackToPreviousStep"
            />
          </div>
        </div>
    </Dialog>
  </div>
</template>

<script setup lang="ts">
import Dialog from "primevue/dialog";

import { computed, ref } from "vue";
import SearchImageWrapper from "@/blocks/HomeWrapper/Search/DragAndDrop/Image/SearchImageWrapper.vue";
import DropImageModalContent from "@/blocks/HomeWrapper/Search/DragAndDrop/DropImageModalContent/DropImageModalContent.vue";
import { uploadImage } from "@/api/search";
import { useImageSearch } from "@/stores/imageSearch";
import { useToast } from "primevue/usetoast";
import TempImageSelection from '@/blocks/ImageSelectio/TempImageSelection.vue'

const imageSearch = useImageSearch();
const toast = useToast();



const visible = ref(false);
const dialogFileUpload = ref();

const imageHeightBasedOnScreen = computed(() => window.screen.height * 0.50);
const base64data = computed(() => imageSearch.imageBase64Data);
const file = computed(() => imageSearch.imageFile);

const step2 = ref(false)

const currentModalContentSteps = ref({
  step1:{
    modalHeader: ' ',
    active:true,
  },
  step2:{
    modalHeader:'Select area',
    active:false,
  }
})

const showToast = (msg) => {
  toast.add({ severity: "error", summary: "Info", detail: msg, life: 3000 });
};

function handlerBase64Data(base64ImageData) {
  imageSearch.imageBase64Data = base64ImageData;
  dialogFileUpload.value.close();
}

function handleBackToPreviousStep() {
  currentModalContentSteps.value.step1.active = true
  currentModalContentSteps.value.step2.active = false
}

const customBase64Uploader = async (event) => {
  imageSearch.imageFile = event.files[0];
  const reader = new FileReader();
  let blob = await fetch(file.value.objectURL).then((r) => r.blob()); //blob:url

  reader.readAsDataURL(blob);

  reader.onloadend = async function () {
    imageSearch.imageBase64Data = reader.result;

    const response = await uploadImage(file.value);

    if (response.status === 200) {
      imageSearch.currentImageLink = response.data.img_url;
    } else {
      showToast("Что то пошло не так ошибка в ответе от сервера");
    }

    currentModalContentSteps.value.step1.active = false
    currentModalContentSteps.value.step2.active = true
  };
};


function cropImageHandler (imageCode)  {
  imageSearch.croppedImageLink = imageCode
}
</script>

<style scoped>

.select-and-upload-image-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width:640px;
  max-height: 90vh;
  background: white;
  border-radius: 20px;
}


</style>
